.my-trip05-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.my-trip05-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.my-trip05-container02 {
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-start;
  margin-top: 30px;
  align-items: center;
  justify-content: flex-start;
}
.my-trip05-navlink {
  display: contents;
}
.my-trip05-image {
  width: 40px;
  align-self: center;
  margin-left: 20px;
  text-decoration: none;
}
.my-trip05-heading {
  margin-left: 25%;
}
.my-trip05-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 1.5px;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-color: #E1E1E1;
}
.my-trip05-container04 {
  flex: 0 0 auto;
  width: 90%;
  height: 65px;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  margin-left: 20px;
  padding-top: 20px;
  border-color: #787878;
  border-style: solid;
  border-width: 1px;
  padding-left: 25px;
  border-radius: 35px;
  flex-direction: column;
  padding-bottom: 20px;
}
.my-trip05-text02 {
  color: #737373;
  font-size: 18px;
}
.my-trip05-container05 {
  flex: 0 0 auto;
  width: 90%;
  height: 65px;
  display: flex;
  position: relative;
  margin-top: 20px;
  align-items: flex-start;
  margin-left: 20px;
  padding-top: 20px;
  border-color: #787878;
  border-style: solid;
  border-width: 1px;
  padding-left: 25px;
  border-radius: 35px;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-start;
}
.my-trip05-text05 {
  top: -8px;
  left: 30px;
  color: #787878;
  position: absolute;
  background-color: #ffffff;
}
.my-trip05-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 15px;
  justify-content: space-between;
}
.my-trip05-text06 {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.my-trip05-image1 {
  width: 30px;
  align-self: flex-end;
  object-fit: cover;
}
.my-trip05-container07 {
  flex: 0 0 auto;
  width: 90%;
  height: 1.5px;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  margin-left: 5%;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-color: #E1E1E1;
}
.my-trip05-text09 {
  margin-top: 30px;
  margin-left: 20px;
}
.my-trip05-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 25px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-start;
}
.my-trip05-container09 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.my-trip05-text10 {
  color: var(--dl-color-gray-500);
}
.my-trip05-text11 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  margin-top: 5px;
  font-weight: 700;
}
.my-trip05-radiobutton {
  width: 20px;
  height: 20px;
  align-self: center;
}
.my-trip05-container10 {
  flex: 0 0 auto;
  width: 90%;
  height: 1.5px;
  display: flex;
  margin-top: 30px;
  align-items: flex-start;
  margin-left: 5%;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-color: #E1E1E1;
}
.my-trip05-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 25px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-start;
}
.my-trip05-container12 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.my-trip05-text12 {
  color: var(--dl-color-gray-500);
}
.my-trip05-text13 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  margin-top: 5px;
  font-weight: 700;
}
.my-trip05-radiobutton1 {
  width: 20px;
  height: 20px;
  align-self: center;
}
.my-trip05-container13 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100px;
  display: flex;
  position: absolute;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.my-trip05-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 1.5px;
  display: flex;
  margin-top: 0px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-color: #E1E1E1;
}
.my-trip05-navlink1 {
  display: contents;
}
.my-trip05-image2 {
  width: 80%;
  margin-top: 15px;
  object-fit: cover;
  margin-left: 10%;
  text-decoration: none;
}
