.visa-on-arrival02-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.visa-on-arrival02-container01 {
    gap: 30px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-self: flex-start;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
}

.visa-on-arrival02-container02 {
    gap: 15px;
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-self: flex-start;
    margin-top: 50px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.visa-on-arrival02-image {
    width: 50px;
    object-fit: cover;
}

.visa-on-arrival02-text {
    font-size: 20px;
    font-weight: 700;
}

.visa-on-arrival02-text01 {
    color: var(--dl-color-gray-500);
    font-size: 12px;
    line-height: 1.5;
}

.visa-on-arrival02-text03 {
    font-weight: 700;
}

.visa-on-arrival02-container03 {
    gap: 30px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.visa-on-arrival02-container04 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.visa-on-arrival02-container05 {
    flex: 0 0 auto;
    width: 60%;
    height: 50px;
    display: flex;
    align-items: flex-start;
}

.visa-on-arrival02-text05 {
    font-weight: 700;
}

.visa-on-arrival02-container06 {
    flex: 0 0 auto;
    width: 40%;
    height: auto;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    justify-content: flex-end;
}

.visa-on-arrival02-text06 {
    color: var(--dl-color-gray-700);
    font-size: 13px;
    text-align: right;
}

.visa-on-arrival02-container07 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.visa-on-arrival02-container08 {
    flex: 0 0 auto;
    width: 60%;
    height: 50px;
    display: flex;
    align-items: flex-start;
}

.visa-on-arrival02-text07 {
    font-weight: 700;
}

.visa-on-arrival02-container09 {
    flex: 0 0 auto;
    width: 40%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.visa-on-arrival02-text08 {
    color: var(--dl-color-gray-700);
    font-size: 13px;
    text-align: right;
}

.visa-on-arrival02-container10 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
}

.visa-on-arrival02-container11 {
    flex: 0 0 auto;
    width: 60%;
    height: 50px;
    display: flex;
    align-items: flex-start;
}

.visa-on-arrival02-text09 {
    font-weight: 700;
}

.visa-on-arrival02-container12 {
    flex: 0 0 auto;
    width: 40%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.visa-on-arrival02-text10 {
    color: var(--dl-color-gray-700);
    font-size: 13px;
    text-align: right;
}

.visa-on-arrival02-container13 {
    gap: 10px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: 100px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.visa-on-arrival02-container14 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.visa-on-arrival02-navlink {
    color: rgb(197, 179, 88);
    width: 90%;
    height: auto;
    font-size: 16px;
    align-self: center;
    text-align: center;
    padding-top: 15px;
    border-color: #C5B358;
    border-radius: 16px;
    padding-bottom: 15px;
    text-decoration: none;
    background-color: var(--dl-color-gray-white);
}

.visa-on-arrival02-container15 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.visa-on-arrival02-navlink1 {
    color: var(--dl-color-gray-white);
    width: 90%;
    height: auto;
    font-size: 16px;
    align-self: center;
    text-align: center;
    padding-top: 15px;
    border-color: var(--dl-color-gray-900);
    border-radius: 16px;
    padding-bottom: 15px;
    text-decoration: none;
    background-color: rgb(197, 179, 88);
}
