.flight-status02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #f6f5f2;
}
.flight-status02-navlink {
  display: contents;
}
.flight-status02-icon {
  top: 28px;
  fill: #D9D9D9;
  left: 16px;
  width: 35px;
  height: 35px;
  position: absolute;
  text-decoration: none;
}
.flight-status02-text {
  top: 35px;
  left: 0px;
  color: var(--dl-color-gray-white);
  right: 0px;
  width: var(--dl-size-size-medium);
  margin: auto;
  position: absolute;
  text-align: center;
}
.flight-status02-icon2 {
  top: 28px;
  fill: #D9D9D9;
  right: 30px;
  width: 30px;
  height: 30px;
  position: absolute;
}
.flight-status02-image {
  align-self: stretch;
  object-fit: cover;
}
.flight-status02-image1 {
  top: var(--dl-space-space-sixunits);
  left: 0px;
  right: 0px;
  width: 95%;
  position: absolute;
  margin-top: auto;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
}
.flight-status02-container1 {
  width: 100%;
  height: 469px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  background-color: #f6f5f2;
}
.flight-status02-container2 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 121px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.flight-status02-navlink1 {
  display: contents;
}
.flight-status02-image2 {
  width: 85%;
  height: 50px;
  object-fit: cover;
  border-color: #c5b358;
  border-width: 1px;
  border-radius: 30px;
  text-decoration: none;
}
