.activity02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.activity02-pasted-image {
  width: 100%;
  height: 100%;
}
.activity02-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 121px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activity02-receipt-button {
  color: rgb(255, 255, 255);
  width: 90%;
  border: #C5B358;
  height: 50px;
  font-size: 14px;
  background: #C5B358;
  font-style: normal;
  text-align: center;
  flex-shrink: 0;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 18px;
  border-radius: 90px;
  background-image: initial;
}
.activity02-navlink {
  text-decoration: none;
}
.activity02-navlink1 {
  top: 52px;
  left: 18px;
  width: 60px;
  height: 60px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
