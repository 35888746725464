.my-trip02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  flex-direction: column;
  justify-content: flex-start;
}
.my-trip02-text {
  align-self: flex-start;
  margin-top: 50px;
  margin-left: 15px;
}
.my-trip02-navlink {
  display: contents;
}
.my-trip02-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 200;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.my-trip02-image1 {
  width: 282px;
  height: 211px;
  margin-top: 80px;
  object-fit: cover;
}
.my-trip02-text01 {
  margin-top: 20px;
}
.my-trip02-text02 {
  width: 80%;
  margin-top: 10px;
  text-align: center;
}
.my-trip02-button {
  color: #ffffff;
  margin-top: 20px;
  padding-top: 10px;
  border-width: 0px;
  padding-left: 30px;
  border-radius: 17px;
  padding-bottom: 10px;
  background-color: #c5b358;
}
.my-trip02-container1 {
  flex: 0 0 auto;
  left: 1px;
  width: 100%;
  bottom: 0px;
  height: 300px;
  display: flex;
  z-index: 202;
  position: absolute;
  align-self: center;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.my-trip02-container2 {
  flex: 0 0 auto;
  width: 15%;
  height: 5px;
  display: flex;
  align-self: center;
  margin-top: 5px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: #D9D9D9;
  border-top-left-radius: 10;
  border-top-right-radius: 10;
  border-bottom-left-radius: 10;
  border-bottom-right-radius: 10;
}
.my-trip02-text03 {
  z-index: 205;
  align-self: center;
  margin-top: 20px;
}
.my-trip02-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
}
.my-trip02-container4 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.my-trip02-navlink1 {
  display: contents;
}
.my-trip02-image2 {
  width: 50px;
  object-fit: cover;
  text-decoration: none;
}
.my-trip02-hotels {
  font-size: 14px;
}
.my-trip02-container5 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.my-trip02-image3 {
  width: 50px;
  object-fit: cover;
}
.my-trip02-hotels1 {
  font-size: 14px;
}
.my-trip02-container6 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.my-trip02-image4 {
  width: 50px;
  object-fit: cover;
}
.my-trip02-hotels2 {
  font-size: 14px;
}
.my-trip02-container7 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.my-trip02-image5 {
  width: 50px;
  object-fit: cover;
}
.my-trip02-hotels3 {
  font-size: 14px;
}
