.bus02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus02-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.bus02-container2 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid lightgray;
  flex-direction: column;
  background-color: white;
}
.bus02-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.bus02-navlink {
  display: contents;
}
.bus02-image {
  z-index: 5;
  margin-top: auto;
  object-fit: cover;
  margin-left: 10px;
  margin-bottom: auto;
  text-decoration: none;
}
.bus02-text {
  width: 100%;
  margin: auto;
  text-align: center;
  font-weight: bold;
  margin-left: -40px;
}
.bus02-container4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
}
.bus02-text1 {
  margin-top: 30px;
  font-weight: bold;
  padding-left: 5%;
  margin-bottom: 15px;
}
.bus02-image1 {
  width: 90%;
  margin: 15px auto;
  align-self: center;
  object-fit: fill;
}
.bus02-image2 {
  width: 90%;
  margin: 15px auto;
  align-self: center;
  object-fit: fill;
}
.bus02-image3 {
  width: 90%;
  margin: 15px auto;
  align-self: center;
  object-fit: fill;
}
.bus02-image4 {
  width: 90%;
  margin: 15px auto;
  align-self: center;
  object-fit: fill;
}
.bus02-container5 {
  width: 100%;
  display: flex;
  position: relative;
}
.bus02-image5 {
  width: 90%;
  margin: 15px auto;
  align-self: center;
  object-fit: fill;
}
.bus02-image6 {
  top: 35%;
  right: 10%;
  width: 35px;
  position: absolute;
  object-fit: fill;
}
.bus02-container6 {
  width: 100%;
  height: 50vh;
  display: flex;
  position: relative;
  flex-direction: column;
}
.bus02-image7 {
  width: 100%;
  align-self: center;
  margin-top: auto;
  object-fit: fill;
}
.bus02-navlink1 {
  left: 11px;
  width: 95%;
  bottom: 40px;
  height: 65px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
