.airport-transfer06-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.airport-transfer06-image {
  width: 100%;
  object-fit: cover;
}
.airport-transfer06-navlink {
  top: 48px;
  left: 11px;
  width: 100px;
  height: 100px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
.airport-transfer06-navlink1 {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 160px;
  opacity: 0;
  z-index: 100;
  position: fixed;
  text-decoration: none;
}
