.bus05-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus05-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: %;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.bus05-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.bus05-navlink {
  width: 200px;
  height: 60px;
  opacity: 0;
  z-index: 10;
  margin-top: -100px;
  margin-right: -235px;
  text-decoration: none;
}
.bus05-navlink1 {
  top: 81px;
  left: 29px;
  width: 25px;
  height: 25px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
