.hotel-payment1-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.hotel-payment1-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 1000px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.hotel-payment1-image {
    width: 100%;
    object-fit: cover;
}

.hotel-payment1-navlink {
    top: 52px;
    left: 12px;
    color: rgb(154, 134, 134);
    width: 100px;
    height: 100px;
    z-index: 200;
    position: absolute;
    text-decoration: none;
}

.hotel-payment1-container2 {
    flex: 0 0 auto;
    width: 200px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.hotel-payment1-navlink1 {
    color: rgb(154, 134, 134);
    right: 0px;
    width: 100%;
    bottom: 0;
    height: 100px;
    z-index: 200;
    position: fixed;
    text-decoration: none;
}
