.meet-greet05-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.meet-greet05-container1 {
  width: 100%;
  border: 2px solid gray;
  height: 100px;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: flex-start;
}
.meet-greet05-navlink {
  display: contents;
}
.meet-greet05-icon {
  width: 40px;
  height: 40px;
  align-self: center;
  margin-left: 15px;
  text-decoration: none;
}
.meet-greet05-text {
  color: var(--dl-color-gray-black);
  height: 20px;
  font-size: 19px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 15px;
  letter-spacing: 2px;
}
.meet-greet05-text02 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet05-navlink1 {
  display: contents;
}
.meet-greet05-icon2 {
  width: 35px;
  height: 35px;
  margin-left: 35px;
  text-decoration: none;
}
.meet-greet05-container2 {
  top: 0px;
  flex: 0.5;
  left: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  position: absolute;
  align-self: left;
  border-color: #b91313;
  border-style: solid;
  justify-content: flex-start;
  background-color: transparent;
  border-bottom-width: 4px;
}
.meet-greet05-text05 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 20px;
  align-self: flex-start;
  margin-top: 30px;
  margin-left: var(--dl-space-space-twounits);
  white-space: nowrap;
}
.meet-greet05-text08 {
  color: var(--dl-color-gray-black);
  width: 90%;
  height: 50px;
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  letter-spacing: 2px;
}
.meet-greet05-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.meet-greet05-textinput {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: justify;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet05-textinput1 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  padding-top: 0px;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
  padding-bottom: 0px;
}
.meet-greet05-textinput2 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  padding-top: 0.5rem;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet05-textinput3 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet05-textinput4 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  margin-right: 0px;
  border-radius: 30px;
  margin-bottom: 0px;
}
.meet-greet05-textinput5 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
  margin-bottom: var(--dl-space-space-twounits);
}
.meet-greet05-container4 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 121px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.meet-greet05-container5 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.meet-greet05-text13 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet05-text16 {
  color: var(--dl-color-gray-black);
  width: auto;
  height: 20px;
  font-size: 22px;
  align-self: flex-start;
  text-align: left;
  white-space: nowrap;
}
.meet-greet05-navlink2 {
  display: contents;
}
.meet-greet05-image {
  width: 120px;
  height: 50px;
  object-fit: cover;
  border-radius: 30px;
  text-decoration: none;
}
