.bus07-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus07-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.bus07-image {
  width: 100%;
  object-fit: cover;
}
.bus07-container2 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0.5;
  position: absolute;
  background-color: var(--dl-color-gray-500);
}
.bus07-image1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  opacity: 1;
  position: absolute;
  object-fit: cover;
}
.bus07-navlink {
  top: 0px;
  width: 100%;
  height: 1170px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
