.staycation04-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.staycation04-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.staycation04-navlink {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
