.staycation02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.staycation02-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.staycation02-navlink {
  top: 70px;
  left: 14px;
  width: 50px;
  height: 50px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
.staycation02-navlink1 {
  left: 49px;
  width: 350px;
  bottom: -166px;
  height: 400px;
  position: absolute;
  text-decoration: none;
}
