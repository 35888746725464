.activity01-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
}
.activity01-image {
  width: 100%;
  height: 100%;
}
.activity01-bus-activity-text {
  top: 178px;
  left: 7px;
  width: 340px;
  height: 150px;
  position: absolute;
  text-decoration: none;
}
.activity01-erl-activity-text {
  top: 344px;
  right: 11px;
  width: 340px;
  height: 150px;
  position: absolute;
  text-decoration: none;
}
.activity01-navlink {
  top: 41px;
  right: 9px;
  width: 168px;
  height: 70px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
.activity01-container1 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 8px;
  display: flex;
  position: fixed;
  align-items: flex-start;
}
