.my-trip04-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.my-trip04-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.my-trip04-container2 {
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-start;
  margin-top: 30px;
  align-items: center;
  justify-content: flex-start;
}
.my-trip04-navlink {
  display: contents;
}
.my-trip04-image {
  width: 40px;
  align-self: center;
  margin-left: 20px;
  text-decoration: none;
}
.my-trip04-heading {
  margin-left: 25%;
}
.my-trip04-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 1.5px;
  display: flex;
  margin-top: 25px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-color: #E1E1E1;
}
.my-trip04-text2 {
  margin-top: 50px;
  margin-left: 20px;
}
.my-trip04-container4 {
  flex: 0 0 auto;
  width: 90%;
  height: 65px;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;
  margin-left: 20px;
  padding-top: 20px;
  border-color: #787878;
  border-style: solid;
  border-width: 1px;
  padding-left: 25px;
  border-radius: 35px;
  flex-direction: column;
  padding-bottom: 20px;
}
.my-trip04-text5 {
  font-size: 22px;
}
.my-trip04-container5 {
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100px;
  display: flex;
  position: absolute;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.my-trip04-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: 1.5px;
  display: flex;
  margin-top: 0px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-color: #E1E1E1;
}
.my-trip04-navlink1 {
  display: contents;
}
.my-trip04-image1 {
  width: 80%;
  margin-top: 15px;
  object-fit: cover;
  margin-left: 10%;
  text-decoration: none;
}
