.flight-status01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #f6f5f2;
}
.flight-status01-navlink {
  display: contents;
}
.flight-status01-icon {
  top: 28px;
  fill: #D9D9D9;
  left: 31px;
  width: 35px;
  height: 35px;
  position: absolute;
  text-decoration: none;
}
.flight-status01-text {
  top: 122px;
  left: 37px;
  color: var(--dl-color-gray-white);
  position: absolute;
}
.flight-status01-text2 {
  color: var(--dl-color-gray-white);
}
.flight-status01-text5 {
  top: 84px;
  left: 33px;
  color: rgb(255, 255, 255);
  position: absolute;
  font-size: 30px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 5px;
  letter-spacing: 2px;
}
.flight-status01-image {
  align-self: stretch;
  object-fit: cover;
}
.flight-status01-container1 {
  top: 200px;
  left: 0px;
  right: 0px;
  width: 95%;
  height: 266px;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: gray;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  justify-content: flex-start;
  background-color: white;
}
.flight-status01-text6 {
  top: 100px;
  left: 69px;
  z-index: 999;
  position: absolute;
  font-size: 18px;
  background-color: var(--dl-color-gray-white);
}
.flight-status01-textinput {
  top: 32px;
  left: 33px;
  width: 85%;
  height: 50px;
  margin: auto;
  position: absolute;
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.flight-status01-textinput1 {
  top: 109px;
  left: 33px;
  width: 85%;
  height: 50px;
  margin: auto;
  position: absolute;
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.flight-status01-icon2 {
  top: 124px;
  right: 66px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  position: absolute;
}
.flight-status01-navlink1 {
  display: contents;
}
.flight-status01-image1 {
  top: top;
  left: 0px;
  right: 0px;
  width: 85%;
  bottom: 27px;
  height: 50px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.flight-status01-image2 {
  width: 100%;
  margin-top: 50%;
  object-fit: cover;
}
