.bus0-home-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.bus0-home-page-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bus0-home-page-container02 {
  width: 100%;
  height: 50px;
  display: flex;
  z-index: 5;
  align-items: flex-start;
  flex-direction: row;
  background-color: white;
}
.bus0-home-page-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid lightgray;
}
.bus0-home-page-navlink {
  display: contents;
}
.bus0-home-page-image {
  z-index: 2;
  margin-top: auto;
  object-fit: cover;
  margin-left: 10px;
  margin-bottom: auto;
  text-decoration: none;
}
.bus0-home-page-text {
  width: 100%;
  margin: auto;
  text-align: center;
  font-weight: bold;
  margin-left: -40px;
}
.bus0-home-page-container04 {
  gap: 25px;
  width: 100%;
  height: 75px;
  display: flex;
  z-index: 1;
  background: white;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.bus0-home-page-button {
  color: White;
  font-color: white;
  margin-left: 20px;
  background-color: #C5B358;
}
.bus0-home-page-button1 {
  opacity: 0;
}
.bus0-home-page-button2 {
  opacity: 0;
}
.bus0-home-page-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.bus0-home-page-text01 {
  color: white;
  width: 100%;
  display: inline-block;
  z-index: 2;
  position: absolute;
  margin-top: -30px;
  text-align: center;
}
.bus0-home-page-image1 {
  width: 100%;
  height: 171;
  z-index: 1;
  object-fit: cover;
}
.bus0-home-page-container06 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  z-index: 1;
  box-shadow: 0px 1px 0px 1px lightgray;
  margin-top: -50px;
  align-items: flex-start;
  padding-top: 15px;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 15px;
  background-color: white;
}
.bus0-home-page-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.bus0-home-page-text07 {
  color: gray;
  width: 90%;
  margin: 20px auto;
  font-size: 14px;
  padding-top: 15px;
  border-color: var(--dl-color-gray-700);
  border-style: solid;
  border-width: 1px;
  padding-left: 25px;
  border-radius: 25px;
  padding-bottom: 15px;
}
.bus0-home-page-container08 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.bus0-home-page-text10 {
  color: gray;
  width: 90%;
  margin: 20px auto;
  font-size: 14px;
  padding-top: 15px;
  border-color: var(--dl-color-gray-700);
  border-style: solid;
  border-width: 1px;
  padding-left: 25px;
  border-radius: 25px;
  padding-bottom: 15px;
}
.bus0-home-page-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.bus0-home-page-container10 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-direction: column;
  padding-bottom: 15px;
}
.bus0-home-page-container11 {
  width: 80%;
  border: 1 solid black;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
}
.bus0-home-page-text13 {
  top: -5px;
  margin: 0px auto;
  position: absolute;
  font-size: 10px;
  background-color: white;
}
.bus0-home-page-text16 {
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  border-color: var(--dl-color-gray-700);
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  padding-bottom: 10px;
}
.bus0-home-page-container12 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-direction: column;
  padding-bottom: 15px;
}
.bus0-home-page-container13 {
  width: 80%;
  border: 1 solid black;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
}
.bus0-home-page-text19 {
  color: gray;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
  border-color: var(--dl-color-gray-700);
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  padding-bottom: 10px;
}
.bus0-home-page-container14 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 15px;
  flex-direction: column;
  padding-bottom: 15px;
}
.bus0-home-page-navlink1 {
  color: white;
  width: 90%;
  height: 40px;
  align-self: center;
  text-align: center;
  border-width: 0px;
  border-radius: 25px;
  text-decoration: none;
  background-color: #C5B358;
}
.bus0-home-page-navlink2 {
  display: contents;
}
.bus0-home-page-container15 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  z-index: 1;
  box-shadow: 0px 1px 0px 1px lightgray;
  margin-top: 50px;
  align-items: flex-start;
  padding-top: 15px;
  border-radius: 10px;
  flex-direction: column;
  padding-bottom: 15px;
  text-decoration: none;
  background-color: white;
}
.bus0-home-page-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.bus0-home-page-container17 {
  flex: 0 0 auto;
  width: 80px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-direction: column;
  padding-bottom: 15px;
}
.bus0-home-page-container18 {
  width: 80%;
  border: 1 solid black;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 25px;
  flex-direction: column;
}
.bus0-home-page-image2 {
  width: 50px;
  object-fit: cover;
}
.bus0-home-page-container19 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  align-items: center;
  padding-top: 15px;
  flex-direction: column;
  padding-bottom: 15px;
}
.bus0-home-page-text22 {
  align-self: start;
  font-weight: bold;
  background-color: white;
}
.bus0-home-page-text25 {
  color: gray;
  width: 100%;
  font-size: 14px;
  align-self: start;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bus0-home-page-container20 {
  top: 0;
  flex: 0 0 auto;
  width: 100%;
  height: 115%;
  display: flex;
  z-index: 0;
  position: absolute;
  align-items: flex-start;
  background-color: #f6f5f2;
}
.bus0-home-page-container21 {
  flex: 0 0 auto;
  width: 90%;
  display: flex;
  align-items: flex-start;
}
.bus0-home-page-text28 {
  z-index: 2;
  align-self: start;
  margin-top: 30px;
  font-weight: bold;
}
.bus0-home-page-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  z-index: 2;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
}
.bus0-home-page-image3 {
  width: 90%;
  height: auto;
  margin: 10px auto;
  object-fit: cover;
}
.bus0-home-page-image4 {
  width: 90%;
  height: auto;
  margin: 10px auto;
  object-fit: cover;
}
.bus0-home-page-image5 {
  width: 90%;
  height: auto;
  margin: 10px auto;
  object-fit: cover;
}
.bus0-home-page-image6 {
  width: 90%;
  height: auto;
  margin: 10px auto;
  object-fit: cover;
}
.bus0-home-page-image7 {
  width: 90%;
  height: auto;
  margin: 10px auto;
  object-fit: cover;
}
