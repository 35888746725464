.erl07-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.erl07-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.erl07-image {
  width: 100%;
  z-index: 100;
  align-self: flex-start;
  object-fit: cover;
}
.erl07-navlink {
  display: contents;
}
.erl07-image1 {
  width: 100%;
  z-index: 100;
  align-self: flex-start;
  object-fit: cover;
  text-decoration: none;
}
.erl07-navlink1 {
  top: 40px;
  left: 0px;
  width: 100px;
  height: 100px;
  z-index: 200;
  position: absolute;
  text-decoration: none;
}
