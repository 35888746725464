.ehailing06-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.ehailing06-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.ehailing06-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ehailing06-text {
  left: 145px;
  width: var(--dl-size-size-xlarge);
  bottom: 111px;
  height: var(--dl-size-size-medium);
  position: absolute;
  text-decoration: none;
}
