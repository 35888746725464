.duty-free07-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.duty-free07-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free07-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.duty-free07-navlink {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 120px;
  position: absolute;
  text-decoration: none;
}
