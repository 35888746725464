.meet-greet06-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.meet-greet06-container1 {
  width: 100%;
  border: 2px solid gray;
  height: 100px;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: flex-start;
}
.meet-greet06-navlink {
  display: contents;
}
.meet-greet06-icon {
  width: 40px;
  height: 40px;
  align-self: center;
  margin-left: 15px;
  text-decoration: none;
}
.meet-greet06-text {
  color: var(--dl-color-gray-black);
  height: 20px;
  font-size: 19px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 40px;
  letter-spacing: 2px;
}
.meet-greet06-navlink1 {
  display: contents;
}
.meet-greet06-icon2 {
  width: 35px;
  height: 35px;
  align-self: center;
  margin-left: 20%;
  text-decoration: none;
}
.meet-greet06-image {
  width: 100%;
  object-fit: cover;
}
.meet-greet06-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meet-greet06-textinput {
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  border-radius: 0px;
  border-right-color: transparent;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.meet-greet06-button {
  color: var(--dl-color-gray-white);
  border-left: transparent;
  border-color: rgb(197, 179, 88);
  border-width: 1px;
  border-radius: 0px;
  background-color: rgb(197, 179, 88);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.meet-greet06-image1 {
  width: 100%;
  object-fit: cover;
}
.meet-greet06-navlink2 {
  display: contents;
}
.meet-greet06-image2 {
  width: 100%;
  margin-top: 15px;
  object-fit: cover;
  border-color: #f6f5f2;
  border-width: 0px;
  text-decoration: none;
  border-top-width: 15px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.meet-greet06-image3 {
  width: 100%;
  margin-top: 0px;
  object-fit: cover;
  border-color: #f6f5f2;
  border-width: 0px;
  border-top-width: 15px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.meet-greet06-container3 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 121px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.meet-greet06-container4 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.meet-greet06-text03 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet06-text06 {
  color: var(--dl-color-gray-black);
  width: auto;
  height: 20px;
  font-size: 22px;
  align-self: flex-start;
  text-align: left;
  white-space: nowrap;
}
.meet-greet06-navlink3 {
  display: contents;
}
.meet-greet06-image4 {
  width: 160px;
  height: 50px;
  object-fit: cover;
  border-radius: 30px;
  text-decoration: none;
}
