.duty-free06-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.duty-free06-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free06-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.duty-free06-navlink {
  top: 71px;
  left: 20px;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  position: absolute;
  text-decoration: none;
}
.duty-free06-navlink1 {
  right: 0px;
  width: var(--dl-size-size-xxlarge);
  bottom: 0px;
  height: var(--dl-size-size-large);
  z-index: 100;
  position: absolute;
  align-self: flex-end;
  text-decoration: none;
}
