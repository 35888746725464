.duty-free03-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.duty-free03-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free03-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.duty-free03-navlink {
  top: 71px;
  left: 20px;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  position: absolute;
  text-decoration: none;
}
.duty-free03-navlink1 {
  top: 66px;
  right: 10px;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  position: absolute;
  text-decoration: none;
}
.duty-free03-navlink2 {
  top: 223px;
  right: 33px;
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xxlarge);
  position: absolute;
  text-decoration: none;
}
