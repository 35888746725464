.erl01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.erl01-navlink {
  top: 221px;
  right: -4px;
  width: 230px;
  height: 70px;
  position: absolute;
  text-decoration: none;
}
.erl01-navlink1 {
  top: 360px;
  left: 0px;
  width: 100%;
  height: 100px;
  position: absolute;
  text-decoration: none;
}
.erl01-image {
  width: 100%;
  z-index: 100;
  align-self: flex-start;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.erl01-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
}
.erl01-navlink2 {
  top: 0px;
  left: 0px;
  width: 100px;
  height: 100px;
  position: absolute;
  margin-top: 40px;
  text-decoration: none;
}
