.duty-free09-container {
  width: 100%;
  display: flex;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #000000;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.duty-free09-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.duty-free09-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.duty-free09-navlink {
  display: contents;
}
.duty-free09-container2 {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.duty-free09-image1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 200;
  position: absolute;
  object-fit: cover;
}
.duty-free09-container3 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  z-index: 202;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 10px;
  justify-content: flex-end;
  background-color: var(--dl-color-gray-white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.duty-free09-container4 {
  gap: 0;
  flex: 0 0 auto;
  width: 15%;
  height: 5px;
  display: flex;
  align-self: center;
  margin-top: 5px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: #D9D9D9;
  border-top-left-radius: 10;
  border-top-right-radius: 10;
  border-bottom-left-radius: 10;
  border-bottom-right-radius: 10;
}
.duty-free09-text {
  z-index: 205;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.duty-free09-container5 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.duty-free09-container6 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.duty-free09-text03 {
  width: 100%;
  text-align: center;
  line-height: 2;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: #E1E1E1;
}
.duty-free09-text06 {
  width: 100%;
  text-align: center;
  line-height: 2;
}
.duty-free09-text09 {
  width: 100%;
  text-align: center;
  line-height: 2;
}
.duty-free09-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: 1.5px;
  display: flex;
  margin-top: 0px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  flex-direction: column;
  background-color: #E1E1E1;
}
.duty-free09-button {
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
  border-radius: 20px;
  text-decoration: none;
  background-color: #C5B358;
}
.duty-free09-text12 {
  color: rgb(255, 255, 255);
}
