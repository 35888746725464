.visa-on-arrival-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.visa-on-arrival-container001 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.visa-on-arrival-backbutton {
  flex: 0 0 auto;
  width: 10%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-navlink {
  display: contents;
}
.visa-on-arrival-image {
  width: 30px;
  align-self: flex-start;
  object-fit: cover;
  margin-left: 5px;
  text-decoration: none;
}
.visa-on-arrival-container002 {
  flex: 0 0 auto;
  width: 90%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text {
  align-self: center;
  font-weight: 700;
}
.visa-on-arrival-container003 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 15px;
  justify-content: center;
  background-color: #C5B358;
}
.visa-on-arrival-text01 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-weight: 700;
}
.visa-on-arrival-container004 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}
.visa-on-arrival-container005 {
  gap: 25px;
  flex: 0 0 auto;
  width: 30%;
  height: auto;
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding-top: 10px;
  border-color: #cbc3c3;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  padding-bottom: 10px;
  justify-content: center;
}
.visa-on-arrival-container006 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.visa-on-arrival-text02 {
  color: var(--dl-color-gray-500);
  font-size: 10px;
  align-self: center;
  font-style: italic;
  text-align: center;
}
.visa-on-arrival-container007 {
  flex: 0 0 auto;
  width: 20%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 0.1px;
  justify-content: center;
}
.visa-on-arrival-container008 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.visa-on-arrival-image1 {
  width: 50%;
  height: 45p;
  align-self: flex-start;
  object-fit: cover;
}
.visa-on-arrival-container009 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.visa-on-arrival-text06 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
  text-align: center;
}
.visa-on-arrival-container010 {
  gap: 25px;
  flex: 0 0 auto;
  width: 30%;
  height: auto;
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding-top: 10px;
  border-color: #cbc3c3;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  padding-bottom: 10px;
  justify-content: center;
}
.visa-on-arrival-container011 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.visa-on-arrival-text10 {
  color: var(--dl-color-gray-500);
  font-size: 10px;
  align-self: center;
  font-style: italic;
  text-align: center;
}
.visa-on-arrival-container012 {
  flex: 0 0 auto;
  width: 20%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 0.5px;
  justify-content: center;
}
.visa-on-arrival-container013 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.visa-on-arrival-image2 {
  width: 50%;
  height: 45px;
  align-self: flex-start;
  object-fit: cover;
}
.visa-on-arrival-container014 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.visa-on-arrival-text11 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
  text-align: center;
}
.visa-on-arrival-container015 {
  gap: 25px;
  flex: 0 0 auto;
  width: 30%;
  height: auto;
  display: flex;
  margin-top: 10px;
  align-items: center;
  padding-top: 10px;
  border-color: #cbc3c3;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  padding-bottom: 10px;
  justify-content: center;
}
.visa-on-arrival-container016 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.visa-on-arrival-text15 {
  color: var(--dl-color-gray-500);
  font-size: 10px;
  align-self: center;
  font-style: italic;
  text-align: center;
}
.visa-on-arrival-container017 {
  flex: 0 0 auto;
  width: 20%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 0.5px;
  justify-content: center;
}
.visa-on-arrival-container018 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.visa-on-arrival-image3 {
  width: 50%;
  height: 45px;
  align-self: flex-start;
  object-fit: cover;
}
.visa-on-arrival-container019 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.visa-on-arrival-text16 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
  text-align: center;
}
.visa-on-arrival-container020 {
  gap: 10px;
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  box-shadow: 5px 5px 5px 0px #d4d4d4;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 10px;
}
.visa-on-arrival-container021 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
}
.visa-on-arrival-text17 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
}
.visa-on-arrival-container022 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #bdbaba;
  border-style: solid;
  border-width: 0.1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-container023 {
  gap: 8px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-container024 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text18 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container025 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container026 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text19 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container027 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput01 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container028 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text20 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container029 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput02 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container030 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text21 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container031 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-textinput03 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container032 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text22 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container033 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-select {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-container034 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text23 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container035 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-select01 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-select02 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-select03 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-container036 {
  gap: 10px;
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  box-shadow: 5px 5px 5px 0px #d4d4d4;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 10px;
}
.visa-on-arrival-container037 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
}
.visa-on-arrival-text24 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
}
.visa-on-arrival-container038 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #bdbaba;
  border-style: solid;
  border-width: 0.1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-container039 {
  gap: 8px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-container040 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text25 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container041 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput04 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container042 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text26 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container043 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-select04 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-select05 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-select06 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-container044 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text27 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container045 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-select07 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-select08 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-select09 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-container046 {
  gap: 8px;
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  box-shadow: 5px 5px 5px 0px #d4d4d4;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 10px;
}
.visa-on-arrival-container047 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
}
.visa-on-arrival-text28 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
}
.visa-on-arrival-container048 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #bdbaba;
  border-style: solid;
  border-width: 0.1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-container049 {
  gap: 3px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-container050 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text29 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container051 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-select10 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-container052 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text30 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container053 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-select11 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-container054 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text31 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container055 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput05 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container056 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text32 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container057 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput06 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container058 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text33 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container059 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput07 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container060 {
  gap: 3px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-container061 {
  gap: 5px;
  flex: 0 0 auto;
  width: autp;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text34 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container062 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-select12 {
  color: var(--dl-color-gray-900);
  height: 20px;
  font-size: 6px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius2);
}
.visa-on-arrival-container063 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text35 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container064 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput08 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container065 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text36 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container066 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput09 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container067 {
  gap: 5px;
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-text37 {
  color: var(--dl-color-gray-500);
  font-size: 8px;
}
.visa-on-arrival-container068 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-textinput10 {
  height: 20px;
  padding: 5px;
  font-size: 6px;
  text-align: left;
  border-color: var(--dl-color-gray-900);
}
.visa-on-arrival-container069 {
  gap: 8px;
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  box-shadow: 5px 5px 5px 0px #d4d4d4;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10px;
  padding-right: 10px;
  flex-direction: column;
  padding-bottom: 10px;
}
.visa-on-arrival-container070 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
}
.visa-on-arrival-text38 {
  color: var(--dl-color-gray-500);
  font-size: 12px;
}
.visa-on-arrival-container071 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #bdbaba;
  border-style: solid;
  border-width: 0.1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-container072 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.visa-on-arrival-container073 {
  gap: 5px;
  flex: 0 0 auto;
  width: 70%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-container074 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.visa-on-arrival-container075 {
  gap: 3px;
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-container076 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text39 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container077 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-button {
  color: var(--dl-color-gray-white);
  padding: 3px;
  font-size: 4px;
  border-width: 0px;
  background-image: linear-gradient(270deg, rgba(233, 242, 90, 0.09) 0%, rgb(200, 193, 32) 100%);
}
.visa-on-arrival-container078 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text40 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container079 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.visa-on-arrival-container080 {
  gap: 3px;
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-container081 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text41 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container082 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-button1 {
  color: var(--dl-color-gray-white);
  padding: 3px;
  font-size: 4px;
  border-width: 0px;
  background-image: linear-gradient(270deg, rgba(233, 242, 90, 0.09) 0%, rgb(200, 193, 32) 100%);
}
.visa-on-arrival-container083 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text42 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container084 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.visa-on-arrival-container085 {
  gap: 3px;
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-container086 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text43 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container087 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-button2 {
  color: var(--dl-color-gray-white);
  padding: 3px;
  font-size: 4px;
  border-width: 0px;
  background-image: linear-gradient(270deg, rgba(233, 242, 90, 0.09) 0%, rgb(200, 193, 32) 100%);
}
.visa-on-arrival-container088 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text44 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container089 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.visa-on-arrival-container090 {
  gap: 3px;
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-container091 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text45 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container092 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-button3 {
  color: var(--dl-color-gray-white);
  padding: 3px;
  font-size: 4px;
  border-width: 0px;
  background-image: linear-gradient(270deg, rgba(233, 242, 90, 0.09) 0%, rgb(200, 193, 32) 100%);
}
.visa-on-arrival-container093 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-text46 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container094 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.visa-on-arrival-container095 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 5px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-text47 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container096 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-image4 {
  width: 50px;
  object-fit: cover;
}
.visa-on-arrival-container097 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 5px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-text50 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container098 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-image5 {
  width: 50px;
  object-fit: cover;
}
.visa-on-arrival-container099 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 5px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-text53 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container100 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-image6 {
  width: 50px;
  object-fit: cover;
}
.visa-on-arrival-container101 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 5px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.visa-on-arrival-text56 {
  color: var(--dl-color-gray-700);
  font-size: 8px;
}
.visa-on-arrival-container102 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.visa-on-arrival-image7 {
  width: 50px;
  object-fit: cover;
}
.visa-on-arrival-container103 {
  gap: 10px;
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  box-shadow: 5px 5px 5px 0px #d4d4d4;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius2);
  margin-bottom: 10px;
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 10px;
}
.visa-on-arrival-container104 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: row;
}
.visa-on-arrival-text59 {
  color: var(--dl-color-gray-500);
  font-size: 10px;
  font-weight: 700;
}
.visa-on-arrival-container105 {
  gap: 8px;
  flex: 0 0 auto;
  width: 45%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
}
.visa-on-arrival-text60 {
  font-size: 10px;
  font-weight: bold;
}
.visa-on-arrival-container106 {
  gap: 10px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 10px;
}
.visa-on-arrival-container107 {
  gap: 3px;
  flex: 0 0 auto;
  width: 60%;
  height: auto;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: row;
}
.visa-on-arrival-container108 {
  flex: 0 0 auto;
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.visa-on-arrival-checkbox {
  width: 10px;
}
.visa-on-arrival-container109 {
  flex: 0 0 auto;
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.visa-on-arrival-text61 {
  font-size: 6px;
}
.visa-on-arrival-container110 {
  gap: 8px;
  flex: 0 0 auto;
  width: 35%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-end;
}
.visa-on-arrival-navlink1 {
  color: var(--dl-color-gray-white);
  font-size: 8px;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  border-width: 0px;
  text-decoration: none;
  background-color: var(--dl-color-primary-theme);
}
