.ignored-bugged-unable-to-delete-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.ignored-bugged-unable-to-delete-root-class-name {
  left: 0px;
  bottom: 0px;
  display: none;
  position: absolute;
}
