.directory-map-container {
  left: calc();
  width: 100%;
  height: 43px;
  display: flex;
  overflow: auto;
  position: absolute;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.directory-map-pasted-image {
  width: 100%;
  height: auto;
  align-self: stretch;
}
.directory-map-navlink {
  top: 41px;
  left: 7px;
  width: 60px;
  height: 60px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
