.bus13-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus13-container1 {
  width: 100%;
  border: 2px solid gray;
  height: 100px;
  display: flex;
  z-index: 999;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.bus13-navlink {
  display: contents;
}
.bus13-icon {
  width: 40px;
  height: 40px;
  align-self: center;
  margin-left: 10px;
  text-decoration: none;
}
.bus13-text {
  top: 40px;
  left: 0px;
  color: var(--dl-color-gray-black);
  right: 0px;
  width: 200px;
  height: 20px;
  margin: auto;
  position: absolute;
  font-size: 19px;
  font-style: normal;
  text-align: center;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.bus13-container2 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 100px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: 0px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.bus13-navlink1 {
  display: contents;
}
.bus13-image {
  width: 85%;
  height: 50px;
  object-fit: cover;
  border-color: #c5b358;
  border-width: 1px;
  border-radius: 30px;
  text-decoration: none;
}
.bus13-container3 {
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: 0;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 0px;
  flex-direction: row;
  justify-content: space-between;
}
.bus13-text3 {
  align-self: center;
  margin-left: 30px;
  margin-right: 0%;
}
.bus13-navlink2 {
  color: #c5b358;
  margin-left: 0%;
  margin-right: 30px;
  text-decoration: none;
}
.bus13-container4 {
  top: 0;
  width: 100%;
  height: 50px;
  margin: 0;
  display: flex;
  position: sticky;
  align-self: end;
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 0px;
  align-content: auto;
  flex-direction: row;
  justify-content: space-between;
}
.bus13-container5 {
  flex: 0 0 auto;
  width: 50%;
  height: 25px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.bus13-icon2 {
  width: 24px;
  height: 24px;
  margin-left: 45px;
}
.bus13-text4 {
  align-self: center;
  margin-left: 25px;
}
.bus13-radiobutton {
  align-self: center;
  margin-right: 35px;
}
.bus13-container6 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 121px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.bus13-navlink3 {
  display: contents;
}
.bus13-image1 {
  width: 85%;
  height: 50px;
  object-fit: cover;
  border-color: #c5b358;
  border-width: 1px;
  border-radius: 30px;
  text-decoration: none;
}
