.hotel-select-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.hotel-select-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.hotel-select-image {
  width: 100%;
  object-fit: cover;
}
.hotel-select-navlink {
  top: 65px;
  left: 15px;
  color: rgb(154, 134, 134);
  width: 50px;
  height: 50px;
  z-index: 200;
  position: absolute;
  text-decoration: none;
}
.hotel-select-navlink1 {
  top: 233px;
  color: rgb(154, 134, 134);
  right: -2px;
  width: 100%;
  height: 800px;
  z-index: 200;
  position: absolute;
  text-decoration: none;
}
