.staycation01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.staycation01-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.staycation01-navlink {
  top: 42px;
  left: 2px;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
.staycation01-navlink1 {
  top: 443px;
  left: 10px;
  width: 450px;
  height: var(--dl-size-size-xxlarge);
  opacity: 0;
  position: absolute;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  text-decoration: none;
}
