.duty-free021-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.duty-free021-container01 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.duty-free021-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free021-container03 {
  width: 100%;
  height: 246px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/dutyfree_assets/backgroundimg-1500w.png");
  background-position: center;
}
.duty-free021-container04 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: var(--dl-size-size-xlarge);
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.duty-free021-navlink {
  display: contents;
}
.duty-free021-image {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
.duty-free021-navlink1 {
  display: contents;
}
.duty-free021-container05 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 200px;
  height: var(--dl-size-size-small);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}
.duty-free021-text {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: DM Sans;
  font-weight: 400;
}
.duty-free021-container06 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.duty-free021-text03 {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-style: normal;
  font-family: DM Sans;
  font-weight: 700;
}
.duty-free021-image01 {
  width: 25px;
  object-fit: cover;
}
.duty-free021-container07 {
  flex: 0 0 auto;
  width: var(--dl-size-size-xlarge);
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.duty-free021-image02 {
  width: auto;
  object-fit: cover;
}
.duty-free021-text04 {
  top: 104px;
  left: 36px;
  color: #FFFFFF;
  width: 170px;
  height: 40px;
  position: absolute;
}
.duty-free021-text07 {
  top: 153px;
  left: 38px;
  color: #FFFFFF;
  width: 400px;
  height: 36px;
  margin: auto;
  position: absolute;
}
.duty-free021-container08 {
  top: 222px;
  left: 77px;
  width: 328px;
  height: 50px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}
.duty-free021-textinput {
  left: -34px;
  width: 400px;
  bottom: -1px;
  height: 50px;
  position: absolute;
  text-align: center;
  border-color: var(--dl-color-gray-700);
  border-radius: 90px;
  padding-bottom: 3px;
}
.duty-free021-image03 {
  top: 17px;
  left: 39px;
  width: 20px;
  height: 20px;
  position: absolute;
  object-fit: cover;
}
.duty-free021-container09 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.duty-free021-image04 {
  width: 376px;
  height: 148px;
  align-self: center;
  object-fit: cover;
}
.duty-free021-container10 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.duty-free021-image05 {
  width: auto;
  object-fit: cover;
}
.duty-free021-image06 {
  width: auto;
  object-fit: cover;
}
.duty-free021-image07 {
  width: auto;
  object-fit: cover;
}
.duty-free021-container11 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-xlarge);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.duty-free021-container12 {
  gap: var(--dl-space-space-twounits);
  width: 373px;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.duty-free021-navlink2 {
  display: contents;
}
.duty-free021-container13 {
  width: 70px;
  height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  background-size: cover;
  justify-content: center;
  text-decoration: none;
  background-image: url("/dutyfree_assets/ellipse%2062-200h.png");
  background-position: center;
}
.duty-free021-image08 {
  width: 40px;
  object-fit: cover;
}
.duty-free021-container14 {
  width: 70px;
  height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("/dutyfree_assets/ellipse%2062-200h.png");
  background-position: center;
}
.duty-free021-image09 {
  width: 40px;
  object-fit: cover;
}
.duty-free021-container15 {
  width: 70px;
  height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("/dutyfree_assets/ellipse%2062-200h.png");
  background-position: center;
}
.duty-free021-image10 {
  width: 40px;
  object-fit: cover;
}
.duty-free021-container16 {
  width: 70px;
  height: 70px;
  display: flex;
  align-self: center;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url("/dutyfree_assets/ellipse%2062-200h.png");
  background-position: center;
}
.duty-free021-image11 {
  width: 40px;
  object-fit: cover;
}
.duty-free021-container17 {
  gap: var(--dl-space-space-twounits);
  width: 405px;
  height: 92px;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.duty-free021-text08 {
  width: 70px;
  align-self: flex-start;
}
.duty-free021-text11 {
  align-self: flex-start;
}
.duty-free021-text14 {
  align-self: flex-start;
}
.duty-free021-text17 {
  align-self: flex-start;
}
.duty-free021-image12 {
  width: var(--dl-size-size-small);
  object-fit: cover;
}
.duty-free021-container18 {
  gap: var(--dl-space-space-unit);
  width: 400px;
  height: 275px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/dutyfree_assets/rectangle%20848-400w.png");
  background-position: center;
}
.duty-free021-image13 {
  width: 180px;
  object-fit: cover;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
}
.duty-free021-text20 {
  width: auto;
  height: auto;
  padding-left: var(--dl-space-space-unit);
}
.duty-free021-container19 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-xsmall);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.duty-free021-text21 {
  color: #C5B358;
  font-style: normal;
  font-family: DM Sans;
  font-weight: 700;
  padding-left: var(--dl-space-space-unit);
}
.duty-free021-image14 {
  width: 20px;
  object-fit: cover;
}
.duty-free021-container20 {
  gap: var(--dl-space-space-twounits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
}
.duty-free021-container21 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  display: flex;
  background-size: cover;
  background-image: url("/dutyfree_assets/rectangle%20850-200h.png");
  background-position: center;
}
.duty-free021-image15 {
  width: auto;
  object-fit: cover;
}
.duty-free021-container22 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  display: flex;
  background-size: cover;
  background-image: url("/dutyfree_assets/rectangle%20850-200h.png");
  background-position: center;
}
.duty-free021-image16 {
  width: auto;
  object-fit: cover;
}
.duty-free021-container23 {
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  display: flex;
  background-size: cover;
  background-image: url("/dutyfree_assets/rectangle%20850-200h.png");
  background-position: center;
}
.duty-free021-image17 {
  width: auto;
  object-fit: cover;
}
.duty-free021-text24 {
  align-self: flex-start;
  font-style: normal;
  font-family: DM Sans;
  font-weight: 700;
  padding-left: var(--dl-space-space-twounits);
}
.duty-free021-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: var(--dl-size-size-large);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free021-container25 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
}
.duty-free021-container26 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free021-container27 {
  width: var(--dl-size-size-large);
  height: 144px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/dutyfree_assets/rectangle%202401-200h.png");
  background-position: center;
}
.duty-free021-image18 {
  width: 75px;
  height: auto;
  object-fit: cover;
}
.duty-free021-text25 {
  font-size: 14px;
  font-style: normal;
  font-family: DM Sans;
  font-weight: 700;
}
.duty-free021-text28 {
  font-style: normal;
  font-weight: 700;
}
.duty-free021-container28 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free021-container29 {
  width: var(--dl-size-size-large);
  height: 144px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/dutyfree_assets/rectangle%202401-200h.png");
  background-position: center;
}
.duty-free021-image19 {
  width: 75px;
  object-fit: cover;
}
.duty-free021-text32 {
  font-size: 14px;
  font-style: normal;
  font-family: DM Sans;
  font-weight: 700;
}
.duty-free021-text33 {
  font-style: normal;
  font-weight: 700;
}
.duty-free021-container30 {
  gap: var(--dl-space-space-threeunits);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-threeunits);
}
.duty-free021-container31 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free021-container32 {
  width: var(--dl-size-size-large);
  height: 144px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/dutyfree_assets/rectangle%202401-200h.png");
  background-position: center;
}
.duty-free021-image20 {
  width: 75px;
  height: auto;
  object-fit: cover;
}
.duty-free021-text37 {
  font-size: 14px;
  font-style: normal;
  font-family: DM Sans;
  font-weight: 700;
}
.duty-free021-text40 {
  font-style: normal;
  font-weight: 700;
}
.duty-free021-container33 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: var(--dl-size-size-large);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.duty-free021-container34 {
  width: var(--dl-size-size-large);
  height: 144px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/dutyfree_assets/rectangle%202401-200h.png");
  background-position: center;
}
.duty-free021-image21 {
  width: 75px;
  object-fit: cover;
}
.duty-free021-text44 {
  font-size: 14px;
  font-style: normal;
  font-family: DM Sans;
  font-weight: 700;
}
.duty-free021-text45 {
  font-style: normal;
  font-weight: 700;
}
