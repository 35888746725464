.bus10-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus10-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background-color: #f6f5f2;
}
.bus10-container02 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
}
.bus10-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.bus10-navlink {
  display: contents;
}
.bus10-image {
  z-index: 5;
  margin-top: auto;
  object-fit: cover;
  margin-left: 10px;
  margin-bottom: auto;
  text-decoration: none;
}
.bus10-text {
  width: 100%;
  margin: auto;
  text-align: center;
  font-weight: bold;
  margin-left: -40px;
}
.bus10-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}
.bus10-container05 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.bus10-image1 {
  width: 80%;
  height: auto;
  z-index: 1;
  border-top: 1px solid lightgray;
  object-fit: fill;
}
.bus10-container06 {
  width: 20%;
  height: 100%;
  display: flex;
  border-top: 1px solid lightgray;
  align-items: center;
  border-left: 1px solid lightgray;
}
.bus10-image2 {
  width: 25%;
  margin: 25% auto;
  z-index: 4;
  object-fit: fill;
}
.bus10-container07 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.bus10-container08 {
  width: 90%;
  margin: 35px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.bus10-navlink1 {
  display: contents;
}
.bus10-image3 {
  width: 100%;
  object-fit: cover;
  text-decoration: none;
}
.bus10-navlink2 {
  display: contents;
}
.bus10-container09 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 640px;
  display: flex;
  opacity: 0.5;
  z-index: 5;
  position: absolute;
  align-items: flex-start;
  text-decoration: none;
  background-color: black;
}
.bus10-navlink3 {
  display: contents;
}
.bus10-image4 {
  width: 100%;
  z-index: 10;
  align-self: center;
  margin-top: -250px;
  object-fit: cover;
  margin-bottom: auto;
  text-decoration: none;
}
.bus10-text1 {
  width: 100%;
  height: 200px;
  margin-top: -1050px;
}
