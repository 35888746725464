.duty-free01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.duty-free01-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.duty-free01-image {
  width: 100%;
  align-self: flex-start;
  object-fit: cover;
}
.duty-free01-navlink {
  display: contents;
}
.duty-free01-overlay {
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.duty-free01-image1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  object-fit: cover;
}
.duty-free01-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
}
.duty-free01-navlink1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 150px;
  position: absolute;
  text-decoration: none;
}
