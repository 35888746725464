.bus03-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus03-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.bus03-image {
  width: 100%;
  object-fit: fill;
}
.bus03-navlink {
  color: white;
  width: 90%;
  height: 70px;
  opacity: 0;
  align-self: center;
  margin-top: -110px;
  text-align: center;
  font-weight: bold;
  line-height: 50px;
  text-decoration: none;
  background-color: #C5B358;
}
.bus03-navlink1 {
  top: 190px;
  left: 25px;
  color: rgb(197, 179, 88);
  width: 90%;
  height: 70px;
  opacity: 0;
  z-index: 2;
  position: absolute;
  align-self: center;
  text-align: center;
  font-weight: bold;
  line-height: 50px;
  padding-left: 18px;
  text-decoration: none;
  background-color: transparent;
}
.bus03-navlink2 {
  top: 70px;
  left: 21px;
  width: 50px;
  height: 50px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
