.meet-greet0-edit-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.meet-greet0-edit-container1 {
  width: 100%;
  border: 2px solid gray;
  height: 100px;
  display: flex;
  z-index: 999;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.meet-greet0-edit-navlink {
  display: contents;
}
.meet-greet0-edit-icon {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  text-decoration: none;
}
.meet-greet0-edit-text {
  top: 40px;
  left: 0px;
  color: var(--dl-color-gray-black);
  right: 0px;
  width: 200px;
  height: 20px;
  margin: auto;
  position: absolute;
  font-size: 19px;
  font-style: normal;
  text-align: center;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet0-edit-container2 {
  top: 0px;
  flex: 0.5;
  left: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  position: absolute;
  align-self: left;
  border-color: #b91313;
  border-style: solid;
  justify-content: flex-start;
  background-color: transparent;
  border-bottom-width: 4px;
}
.meet-greet0-edit-text3 {
  color: var(--dl-color-gray-black);
  width: 90%;
  height: 50px;
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  letter-spacing: 2px;
}
.meet-greet0-edit-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.meet-greet0-edit-textinput {
  color: var(--dl-color-gray-500);
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: justify;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet0-edit-textinput1 {
  color: var(--dl-color-gray-500);
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  padding-top: 0px;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
  padding-bottom: 0px;
}
.meet-greet0-edit-textinput2 {
  color: var(--dl-color-gray-500);
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  padding-top: 0.5rem;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet0-edit-textinput3 {
  color: var(--dl-color-gray-500);
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet0-edit-container4 {
  width: 100%;
  bottom: 0;
  height: 45px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: solid;
  border-width: 0px;
  flex-direction: row;
  justify-content: space-between;
}
.meet-greet0-edit-text6 {
  align-self: center;
  margin-left: 40px;
}
.meet-greet0-edit-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 40px;
}
.meet-greet0-edit-container5 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 121px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meet-greet0-edit-navlink1 {
  display: contents;
}
.meet-greet0-edit-image {
  width: 85%;
  height: 50px;
  object-fit: cover;
  border-color: #c5b358;
  border-width: 1px;
  border-radius: 30px;
  text-decoration: none;
}
