.hotel-book-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.hotel-book-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 1000px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.hotel-book-image {
    width: 100%;
    object-fit: cover;
}

.hotel-book-navlink {
    top: 49px;
    left: 11px;
    color: rgb(154, 134, 134);
    width: 100px;
    height: 100px;
    z-index: 200;
    position: absolute;
    text-decoration: none;
}

.hotel-book-navlink1 {
    left: 0px;
    color: rgb(154, 134, 134);
    width: 100%;
    bottom: 0;
    height: 200px;
    z-index: 200;
    position: fixed;
    text-decoration: none;
}
