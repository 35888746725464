.hotel-container {
    width: 100%;
    display: flex;
    z-index: 100;
    overflow: auto;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    min-height: 100vh;
    align-items: flex-end;
    border-color: #975634;
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
}

.hotel-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 1000px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
}

.hotel-image {
    width: 100%;
    z-index: 100;
    object-fit: cover;
}

.hotel-navlink {
    top: 46px;
    left: 3px;
    color: rgb(154, 134, 134);
    width: 100px;
    height: 100px;
    z-index: 200;
    position: absolute;
    text-decoration: none;
}


.hotel-navlink1 {
    top: 395px;
    color: rgb(154, 134, 134);
    right: 0px;
    width: 100%;
    height: 300px;
    z-index: 200;
    position: absolute;
    text-decoration: none;
}
