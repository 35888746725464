.airport-transfer02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.airport-transfer02-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.airport-transfer02-navlink {
  top: 187px;
  right: 28px;
  width: 200px;
  height: 200px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
.airport-transfer02-navlink1 {
  top: 36px;
  left: 10px;
  width: 100px;
  height: 100px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
