.erl05-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.erl05-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
}
.erl05-image {
  width: 100%;
  align-self: flex-start;
  object-fit: cover;
}
.erl05-navlink {
  display: contents;
}
.erl05-overlay {
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.erl05-image1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  object-fit: cover;
}
.erl05-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
}
.erl05-navlink1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 150px;
  position: absolute;
  text-decoration: none;
}
