.erl08-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.erl08-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
}
.erl08-image {
  width: 100%;
  align-self: flex-start;
  object-fit: cover;
}
.erl08-navlink {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 125px;
  position: absolute;
  text-decoration: none;
}
