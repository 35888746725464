.erl02-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.erl02-image {
    width: 100%;
    align-self: flex-start;
    object-fit: cover;
}

.erl02-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 150px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: dashed;
    border-width: 0px;
}

.erl02-navlink {
    top: 580px;
    left: 0px;
    width: 100%;
    height: 100px;
    position: absolute;
    text-decoration: none;
}

.erl02-navlink1 {
    top: 270px;
    left: 6px;
    width: 230px;
    height: 70px;
    position: absolute;
    text-decoration: none;
}

.erl02-navlink2 {
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    position: absolute;
    margin-top: 40px;
    text-decoration: none;
}
