.ehailing03-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.ehailing03-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.ehailing03-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ehailing03-navlink {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: var(--dl-size-size-large);
  position: absolute;
  text-decoration: none;
}
.ehailing03-navlink1 {
  top: 40px;
  left: 0px;
  color: rgba(0, 0, 0, 0.01);
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  opacity: 0;
  position: absolute;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px;
  text-decoration: underline;
}
