.meet-greet01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.meet-greet01-navlink {
  display: contents;
}
.meet-greet01-icon {
  top: 28px;
  fill: #D9D9D9;
  left: 31px;
  width: 35px;
  height: 35px;
  position: absolute;
  text-decoration: none;
}
.meet-greet01-text {
  top: 122px;
  left: 37px;
  color: var(--dl-color-gray-white);
  position: absolute;
}
.meet-greet01-text02 {
  color: var(--dl-color-gray-white);
}
.meet-greet01-text04 {
  top: 84px;
  left: 33px;
  color: rgb(255, 255, 255);
  position: absolute;
  font-size: 30px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 5px;
  letter-spacing: 2px;
}
.meet-greet01-image {
  align-self: stretch;
  object-fit: cover;
}
.meet-greet01-container01 {
  top: 200px;
  width: 430px;
  height: 359px;
  margin: 0 20px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-color: gray;
  border-style: solid;
  border-width: 2px;
  border-radius: 30px;
  justify-content: flex-start;
  background-color: white;
}
.meet-greet01-image1 {
  top: var(--dl-space-space-twounits);
  left: var(--dl-space-space-twounits);
  width: 175px;
  height: 50px;
  position: absolute;
  object-fit: cover;
}
.meet-greet01-image2 {
  top: var(--dl-space-space-twounits);
  right: var(--dl-space-space-twounits);
  width: 175px;
  height: 50px;
  position: absolute;
  object-fit: cover;
  border-radius: 30px;
}
.meet-greet01-container02 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.meet-greet01-text05 {
  top: 116px;
  left: 69px;
  z-index: 999;
  position: absolute;
  font-size: 18px;
  background-color: var(--dl-color-gray-white);
}
.meet-greet01-textinput {
  top: 126px;
  left: 0px;
  right: 0px;
  width: 85%;
  height: 50px;
  margin: auto;
  position: absolute;
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet01-textinput1 {
  top: 207px;
  left: 0px;
  right: 0px;
  width: 85%;
  height: 50px;
  margin: auto;
  position: absolute;
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet01-icon02 {
  top: 138px;
  right: 58px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  position: absolute;
}
.meet-greet01-navlink1 {
  display: contents;
}
.meet-greet01-image3 {
  left: 0px;
  right: 0px;
  width: 85%;
  bottom: 23px;
  height: 50px;
  margin: auto;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.meet-greet01-container03 {
  top: 582px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
}
.meet-greet01-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-bottom: 2px solid gray;
  flex-direction: row;
}
.meet-greet01-icon04 {
  width: 60px;
  height: 54px;
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.meet-greet01-container05 {
  flex: 0 0 auto;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.meet-greet01-text06 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet01-text09 {
  color: var(--dl-color-gray-black);
  width: 300px;
  word-break: keep-all;
}
.meet-greet01-text12 {
  color: var(--dl-color-gray-black);
}
.meet-greet01-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: flex-start;
  border-bottom: 2px solid gray;
  flex-direction: row;
}
.meet-greet01-icon06 {
  width: 60px;
  height: 54px;
  margin-top: var(--dl-space-space-unit);
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.meet-greet01-container07 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: flex-start;
}
.meet-greet01-text15 {
  color: var(--dl-color-gray-black);
  height: 20px;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet01-text17 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet01-text20 {
  color: var(--dl-color-gray-black);
  width: 300px;
  word-break: keep-all;
}
.meet-greet01-text22 {
  color: var(--dl-color-gray-black);
}
.meet-greet01-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-bottom: 2px solid gray;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 0px;
}
.meet-greet01-icon08 {
  width: 60px;
  height: 54px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.meet-greet01-container09 {
  flex: 0 0 auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.meet-greet01-text24 {
  color: var(--dl-color-gray-black);
  height: 25px;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet01-text29 {
  color: var(--dl-color-gray-black);
  width: 300px;
  height: 50px;
  word-break: keep-all;
}
.meet-greet01-text31 {
  color: var(--dl-color-gray-black);
}
.meet-greet01-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: flex-start;
  border-bottom: 2px solid gray;
  flex-direction: row;
  justify-content: flex-start;
}
.meet-greet01-icon11 {
  width: 60px;
  height: 54px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  margin-left: var(--dl-space-space-unit);
}
.meet-greet01-container11 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 12px;
  align-items: flex-start;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.meet-greet01-text35 {
  color: var(--dl-color-gray-black);
  height: 25px;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet01-text37 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet01-text41 {
  color: var(--dl-color-gray-black);
  width: 300px;
  height: 40px;
  word-break: keep-all;
}
.meet-greet01-text43 {
  color: var(--dl-color-gray-black);
}
