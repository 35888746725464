.activity05-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.activity05-container1 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.activity05-pasted-image {
  width: 100%;
  height: 100%;
}
.activity05-navlink {
  top: 50px;
  left: 9px;
  width: 60px;
  height: 60px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
