.ehailing04-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.ehailing04-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.ehailing04-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ehailing04-navlink {
  left: -4px;
  width: 100%;
  bottom: 1px;
  height: var(--dl-size-size-large);
  position: absolute;
  text-decoration: none;
}
.ehailing04-navlink1 {
  top: 34px;
  left: 12px;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  opacity: 0;
  position: absolute;
  box-shadow: rgb(212, 212, 212) 5px 5px 10px 0px, rgb(212, 212, 212) 5px 5px 10px 0px;
  text-decoration: none;
}
