.meet-greet04-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.meet-greet04-container1 {
  width: 100%;
  border: 2px solid gray;
  height: 100px;
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: flex-start;
}
.meet-greet04-navlink {
  display: contents;
}
.meet-greet04-icon {
  width: 40px;
  height: 40px;
  align-self: center;
  margin-left: 15px;
  text-decoration: none;
}
.meet-greet04-text {
  color: var(--dl-color-gray-black);
  height: 20px;
  font-size: 19px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 15px;
  letter-spacing: 2px;
}
.meet-greet04-text02 {
  color: var(--dl-color-gray-black);
  font-style: normal;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet04-navlink1 {
  display: contents;
}
.meet-greet04-icon2 {
  width: 35px;
  height: 35px;
  margin-left: 35px;
  text-decoration: none;
}
.meet-greet04-container2 {
  top: 0px;
  flex: 0.5;
  left: 0px;
  width: 66%;
  height: 100px;
  display: flex;
  position: absolute;
  align-self: left;
  border-color: #b91313;
  border-style: solid;
  justify-content: flex-start;
  background-color: transparent;
  border-bottom-width: 4px;
}
.meet-greet04-text05 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 20px;
  align-self: flex-start;
  margin-top: 30px;
  margin-left: var(--dl-space-space-twounits);
  white-space: nowrap;
}
.meet-greet04-text08 {
  color: var(--dl-color-gray-black);
  width: 90%;
  height: 50px;
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: var(--dl-space-space-twounits);
  letter-spacing: 2px;
}
.meet-greet04-text10 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet04-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.meet-greet04-textinput {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: justify;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet04-textinput1 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  padding-top: 0px;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
  padding-bottom: 0px;
}
.meet-greet04-textinput2 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  padding-top: 0.5rem;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet04-icon4 {
  top: 198px;
  right: 60px;
  width: 24px;
  height: 24px;
  z-index: 1000;
  position: absolute;
  border-color: var(--dl-color-gray-700);
}
.meet-greet04-textinput3 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
}
.meet-greet04-textinput4 {
  width: 85%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  text-align: justify;
  border-color: var(--dl-color-gray-700);
  border-radius: 30px;
  margin-bottom: var(--dl-space-space-twounits);
}
.meet-greet04-container4 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 121px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.meet-greet04-container5 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.meet-greet04-text14 {
  color: var(--dl-color-gray-black);
  width: auto;
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet04-text17 {
  color: var(--dl-color-gray-black);
  width: auto;
  height: 20px;
  font-size: 22px;
  align-self: flex-start;
  text-align: left;
  white-space: nowrap;
}
.meet-greet04-navlink2 {
  display: contents;
}
.meet-greet04-image {
  width: 120px;
  height: 50px;
  object-fit: cover;
  border-radius: 30px;
  text-decoration: none;
}
