.bus04-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus04-container1 {
  width: 100%;
  display: flex;
}
.bus04-image {
  width: 100%;
  align-self: center;
  object-fit: fill;
}
.bus04-navlink {
  top: 80px;
  right: 31px;
  width: 25px;
  height: 25px;
  opacity: 0;
  position: absolute;
  text-align: center;
  text-decoration: none;
}
