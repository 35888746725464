.-hailing05-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.-hailing05-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.-hailing05-navlink {
  top: 66px;
  left: 16px;
  width: 50px;
  height: 50px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
.-hailing05-navlink1 {
  top: 166px;
  left: 4px;
  width: 450px;
  height: 500px;
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
