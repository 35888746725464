.duty-free02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.duty-free02-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.duty-free02-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.duty-free02-navlink {
  top: 50px;
  left: 0px;
  width: 80px;
  height: var(--dl-size-size-medium);
  position: absolute;
  text-decoration: none;
}
.duty-free02-navlink1 {
  top: 50px;
  left: 79px;
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-medium);
  position: absolute;
  text-decoration: none;
}
.duty-free02-navlink2 {
  top: 454px;
  left: 1px;
  width: var(--dl-size-size-xlarge);
  height: var(--dl-size-size-xxlarge);
  position: absolute;
  text-decoration: none;
}
