.meet-greet02-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.meet-greet02-navlink {
  display: contents;
}
.meet-greet02-icon {
  width: 40px;
  height: 40px;
  align-self: flex-start;
  margin-top: 50px;
  margin-left: 30px;
  text-decoration: none;
}
.meet-greet02-text {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  align-self: flex-start;
  font-style: normal;
  margin-top: 20px;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 30px;
  letter-spacing: 2px;
}
.meet-greet02-text03 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  align-self: flex-start;
  font-style: normal;
  margin-top: 20px;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 30px;
  letter-spacing: 2px;
}
.meet-greet02-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.meet-greet02-text06 {
  color: var(--dl-color-gray-white);
  width: 50px;
  height: 50px;
  margin-top: 20px;
  text-align: center;
  line-height: 3;
  margin-left: 30px;
  border-radius: 10px;
  background-color: #c5b358;
}
.meet-greet02-text09 {
  width: 70%;
  font-size: 20px;
  margin-top: 30px;
  text-align: left;
  word-break: keep-all;
  margin-left: 10px;
}
.meet-greet02-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.meet-greet02-text13 {
  color: var(--dl-color-gray-white);
  width: 50px;
  height: 50px;
  margin-top: 20px;
  text-align: center;
  line-height: 3;
  margin-left: 30px;
  border-radius: 10px;
  background-color: rgb(197, 179, 88);
}
.meet-greet02-text17 {
  width: 70%;
  font-size: 20px;
  margin-top: 25px;
  text-align: left;
  word-break: keep-all;
  margin-left: 10px;
}
.meet-greet02-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.meet-greet02-text18 {
  color: var(--dl-color-gray-white);
  width: 50px;
  height: 50px;
  margin-top: 20px;
  text-align: center;
  line-height: 3;
  margin-left: 30px;
  border-radius: 10px;
  background-color: rgb(197, 179, 88);
}
.meet-greet02-text22 {
  width: 70%;
  font-size: 20px;
  margin-top: 25px;
  text-align: left;
  word-break: keep-all;
  margin-left: 10px;
}
.meet-greet02-container4 {
  flex: 0 0 auto;
  width: 95%;
  height: 110px;
  display: flex;
  border-top: 2px solid gray;
  align-items: flex-start;
  border-bottom: 2px solid gray;
  flex-direction: column;
}
.meet-greet02-text23 {
  color: var(--dl-color-gray-black);
  height: 35px;
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 10px;
  letter-spacing: 2px;
}
.meet-greet02-text25 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-style: normal;
  font-family: Arial;
  font-weight: 600;
  letter-spacing: 2px;
}
.meet-greet02-text29 {
  color: var(--dl-color-gray-black);
  width: 90%;
  align-self: flex-start;
  word-break: break-all;
  margin-left: 10px;
  white-space: nowrap;
}
.meet-greet02-text33 {
  color: var(--dl-color-gray-black);
}
.meet-greet02-container5 {
  flex: 0 0 auto;
  width: 95%;
  height: 110px;
  display: flex;
  border-top: 2px solid gray;
  align-items: flex-start;
  border-bottom: 2px solid gray;
  flex-direction: column;
}
.meet-greet02-text36 {
  color: var(--dl-color-gray-black);
  height: 35px;
  font-size: 20px;
  font-style: normal;
  margin-top: 10px;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 10px;
  letter-spacing: 2px;
}
.meet-greet02-text39 {
  color: var(--dl-color-gray-black);
  width: 90%;
  margin-top: 10px;
  word-break: break-all;
  margin-left: 10px;
  white-space: nowrap;
}
.meet-greet02-text44 {
  color: var(--dl-color-gray-black);
}
.meet-greet02-container6 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 121px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meet-greet02-navlink1 {
  display: contents;
}
.meet-greet02-image {
  width: 85%;
  height: 50px;
  object-fit: cover;
  text-decoration: none;
}
