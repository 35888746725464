.bus08-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus08-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.bus08-image {
  width: 100%;
  object-fit: cover;
}
.bus08-navlink {
  width: 400px;
  height: 60px;
  opacity: 0;
  margin-top: -100px;
  text-decoration: none;
}
