.more-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.more-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: 35px;
  padding-left: 20px;
  flex-direction: column;
  justify-content: flex-start;
}
.more-text {
  margin-bottom: 35px;
}
.more-container02 {
  flex: 0 0 auto;
  width: 95%;
  height: 140px;
  display: flex;
  opacity: 0.9;
  position: relative;
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  border-radius: 20px;
  margin-bottom: 40px;
  background-color: var(--dl-color-primary-theme);
}
.more-image {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 140px;
  position: absolute;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  object-fit: cover;
  border-radius: 20px;
}
.more-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
}
.more-image01 {
  width: 60px;
  z-index: 200;
  object-fit: cover;
}
.more-text01 {
  color: #ffffff;
  z-index: 999;
  margin-left: 20px;
}
.more-image02 {
  width: 50px;
  z-index: 999;
  object-fit: cover;
}
.more-container04 {
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.more-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
}
.more-text05 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image03 {
  width: 40px;
  object-fit: cover;
}
.more-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 20px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 20px;
  background-color: #E1E1E1;
}
.more-container07 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
}
.more-text06 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image04 {
  width: 40px;
  object-fit: cover;
}
.more-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 20px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 20px;
  background-color: #E1E1E1;
}
.more-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
}
.more-text07 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image05 {
  width: 40px;
  object-fit: cover;
}
.more-container10 {
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  margin-top: 60px;
  align-items: flex-start;
  flex-direction: column;
}
.more-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
}
.more-text11 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image06 {
  width: 40px;
  object-fit: cover;
}
.more-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 20px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 20px;
  background-color: #E1E1E1;
}
.more-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
}
.more-text12 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image07 {
  width: 40px;
  object-fit: cover;
}
.more-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 20px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 20px;
  background-color: #E1E1E1;
}
.more-container15 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
}
.more-text13 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image08 {
  width: 40px;
  object-fit: cover;
}
.more-container16 {
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  margin-top: 60px;
  align-items: flex-start;
  flex-direction: column;
}
.more-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
}
.more-text17 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image09 {
  width: 40px;
  object-fit: cover;
}
.more-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 20px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 20px;
  background-color: #E1E1E1;
}
.more-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
}
.more-text20 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image10 {
  width: 40px;
  object-fit: cover;
}
.more-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 20px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 20px;
  background-color: #E1E1E1;
}
.more-container21 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
}
.more-text21 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image11 {
  width: 40px;
  object-fit: cover;
}
.more-container22 {
  flex: 0 0 auto;
  width: 95%;
  display: flex;
  margin-top: 60px;
  align-items: flex-start;
  flex-direction: column;
}
.more-container23 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
}
.more-text25 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image12 {
  width: 40px;
  object-fit: cover;
}
.more-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: 2px;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: 20px;
  align-items: flex-start;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: 20px;
  background-color: #E1E1E1;
}
.more-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 0px;
  align-items: center;
  justify-content: space-between;
}
.more-text28 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
}
.more-image13 {
  width: 40px;
  object-fit: cover;
}
.more-log-out {
  color: var(--dl-color-primary-theme);
  font-size: 20px;
  margin-top: 80px;
  font-weight: bold;
  margin-bottom: 80px;
}
