.extend-explore-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.extend-explore-page-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.extend-explore-page-navlink {
  display: contents;
}
.extend-explore-page-image {
  width: 100%;
  height: 50%;
  opacity: 0.8;
  z-index: 200;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.extend-explore-page-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.extend-explore-page-notification {
  top: 28px;
  flex: 0;
  right: 20px;
  width: 35px;
  height: 35px;
  position: absolute;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius4);
}
.extend-explore-page-homepagebackground {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.extend-explore-page-top-bottom-section {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-color: #c5b358;
}
.extend-explore-page-text {
  top: 316px;
  color: rgb(255, 255, 255);
  right: 13px;
  position: absolute;
  font-size: 10px;
  font-style: normal;
  text-align: left;
  font-family: Nunito;
  font-weight: 700;
  margin-left: 5px;
  letter-spacing: 2px;
}
.extend-explore-page-text01 {
  top: 314px;
  left: 10px;
  color: rgb(255, 255, 255);
  position: absolute;
  font-size: 10px;
  font-style: normal;
  text-align: left;
  font-family: Nunito;
  font-weight: 600;
  margin-left: 5px;
  letter-spacing: 2px;
}
.extend-explore-page-voucher {
  width: 100%;
  height: 90px;
  display: flex;
  align-self: flex-start;
  margin-top: 20px;
  align-items: center;
  margin-left: 0px;
  padding-left: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
  justify-content: center;
}
.extend-explore-page-voucher-section {
  flex: 0 0 auto;
  width: 90%;
  height: 73px;
  display: flex;
  align-self: center;
  align-items: center;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 2px;
  padding-left: 15px;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 15px;
  flex-direction: row;
  justify-content: center;
}
.extend-explore-page-container03 {
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  align-items: center;
  margin-left: 50px;
  padding-left: 20px;
  padding-right: 3px;
  justify-content: center;
}
.extend-explore-page-image01 {
  width: 30px;
  object-fit: cover;
}
.extend-explore-page-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  padding-left: 15px;
  flex-direction: column;
  justify-content: center;
}
.extend-explore-page-text02 {
  font-size: 12px;
  align-self: flex-start;
  margin-bottom: 5px;
}
.extend-explore-page-text05 {
  color: #C5B358;
  font-size: 12px;
  align-self: flex-start;
  text-decoration: underline;
}
.extend-explore-page-text06 {
  top: 230px;
  color: rgb(255, 255, 255);
  right: 0px;
  position: absolute;
  font-size: 20px;
  font-style: normal;
  text-align: left;
  font-family: Arial;
  font-weight: 600;
  margin-left: 5px;
  letter-spacing: 2px;
}
.extend-explore-page-extend {
  gap: 10px;
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: auto;
  display: flex;
  z-index: 202;
  position: absolute;
  align-self: flex-end;
  overflow-y: scroll;
  align-items: flex-end;
  padding-top: 15px;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: dashed;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  padding-bottom: 15px;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.extend-explore-page-container05 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.extend-explore-page-place-to-stay {
  z-index: 205;
  font-size: 12px;
  align-self: flex-start;
  margin-left: 15px;
}
.extend-explore-page-places-to-stay {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.extend-explore-page-navlink01 {
  display: contents;
}
.extend-explore-page-container06 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image02 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels {
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.extend-explore-page-navlink02 {
  display: contents;
}
.extend-explore-page-container07 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image03 {
  width: 45px;
  align-self: center;
  object-fit: cover;
  margin-left: 0px;
}
.extend-explore-page-text07 {
  font-size: 10px;
  align-self: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.extend-explore-page-container08 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.extend-explore-page-travel {
  z-index: 205;
  font-size: 12px;
  align-self: flex-start;
  margin-left: 15px;
}
.extend-explore-page-travel-experiences {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.extend-explore-page-container09 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.extend-explore-page-image04 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-attractions {
  font-size: 10px;
}
.extend-explore-page-container10 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.extend-explore-page-image05 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-events-exhibitions {
  font-size: 10px;
}
.extend-explore-page-container11 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.extend-explore-page-image06 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-water-activity {
  font-size: 10px;
}
.extend-explore-page-container12 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.extend-explore-page-image07 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-experience {
  font-size: 10px;
}
.extend-explore-page-container13 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.extend-explore-page-others {
  z-index: 205;
  font-size: 12px;
  align-self: flex-start;
  margin-left: 15px;
}
.extend-explore-page-others1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.extend-explore-page-navlink03 {
  display: contents;
}
.extend-explore-page-container14 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}
.extend-explore-page-image08 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-duty-free {
  width: 100%;
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-container15 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.extend-explore-page-navlink04 {
  display: contents;
}
.extend-explore-page-image09 {
  width: 45px;
  object-fit: cover;
  text-decoration: none;
}
.extend-explore-page-online-shopping {
  width: 100%;
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-container16 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.extend-explore-page-airport {
  z-index: 205;
  font-size: 12px;
  align-self: flex-start;
  margin-left: 15px;
}
.extend-explore-page-airport-services {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.extend-explore-page-navlink05 {
  display: contents;
}
.extend-explore-page-container17 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image10 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels01 {
  width: 100%;
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-navlink06 {
  display: contents;
}
.extend-explore-page-container18 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image11 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels02 {
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-navlink07 {
  display: contents;
}
.extend-explore-page-container19 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image12 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels03 {
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-container20 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.extend-explore-page-image13 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels04 {
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-container21 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.extend-explore-page-airport1 {
  z-index: 205;
  font-size: 12px;
  align-self: flex-start;
  margin-left: 15px;
}
.extend-explore-page-transportation {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.extend-explore-page-navlink08 {
  display: contents;
}
.extend-explore-page-container22 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image14 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels05 {
  font-size: 10px;
}
.extend-explore-page-navlink09 {
  display: contents;
}
.extend-explore-page-container23 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image15 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels06 {
  width: 100%;
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-navlink10 {
  display: contents;
}
.extend-explore-page-container24 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-image16 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels07 {
  font-size: 10px;
  align-self: center;
  text-align: center;
}
.extend-explore-page-container25 {
  flex: 0 0 auto;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.extend-explore-page-navlink11 {
  display: contents;
}
.extend-explore-page-image17 {
  width: 45px;
  object-fit: cover;
  text-decoration: none;
}
.extend-explore-page-hotels08 {
  font-size: 10px;
}
.extend-explore-page-container26 {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-start;
}
.extend-explore-page-airport2 {
  z-index: 205;
  font-size: 12px;
  align-self: flex-start;
  margin-left: 15px;
}
.extend-explore-page-transportation1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.extend-explore-page-container27 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.extend-explore-page-image18 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels09 {
  font-size: 10px;
}
.extend-explore-page-container28 {
  flex: 0 0 auto;
  width: 25%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.extend-explore-page-image19 {
  width: 45px;
  object-fit: cover;
}
.extend-explore-page-hotels10 {
  width: 100%;
  font-size: 10px;
  text-align: center;
}
.extend-explore-page-container29 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: center;
}
.extend-explore-page-navlink12 {
  display: contents;
}
.extend-explore-page-image20 {
  width: 38px;
  height: 38px;
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  text-decoration: none;
  background-color: #C5B358;
}
