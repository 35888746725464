.home-page-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.home-page-frame {
    width: 100%;
    height: var(--dl-size-size-maxwidth);
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 1px;
}

.home-page-notification {
    top: 28px;
    flex: 0;
    right: 20px;
    width: 35px;
    height: 35px;
    position: absolute;
    object-fit: cover;
    border-radius: var(--dl-radius-radius-radius4);
}

.home-page-homepagebackground {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.home-page-top-bottom-section {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    background-color: #c5b358;
}

.home-page-text {
    top: 316px;
    color: rgb(255, 255, 255);
    right: 13px;
    position: absolute;
    font-size: 10px;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 700;
    margin-left: 5px;
    letter-spacing: 2px;
}

.home-page-text01 {
    top: 314px;
    left: 10px;
    color: rgb(255, 255, 255);
    position: absolute;
    font-size: 10px;
    font-style: normal;
    text-align: left;
    font-family: Nunito;
    font-weight: 600;
    margin-left: 5px;
    letter-spacing: 2px;
}

.home-page-voucher {
    width: 100%;
    height: 90px;
    display: flex;
    align-self: flex-start;
    margin-top: 20px;
    align-items: center;
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    justify-content: center;
}

.home-page-voucher-section {
    gap: 15px;
    flex: 0 0 auto;
    width: 90%;
    height: 73px;
    display: flex;
    align-self: center;
    align-items: center;
    border-color: #c7c7c7;
    border-style: solid;
    border-width: 2px;
    padding-left: 15px;
    border-radius: var(--dl-radius-radius-radius8);
    padding-right: 15px;
    flex-direction: row;
    justify-content: center;
}

.home-page-container1 {
    flex: 0 0 auto;
    width: 20%;
    display: flex;
    align-items: center;
    margin-left: 50px;
    padding-left: 20px;
    padding-right: 3px;
    justify-content: center;
}

.home-page-image {
    width: 30px;
    object-fit: cover;
}

.home-page-container2 {
    flex: 0 0 auto;
    width: 80%;
    height: 100px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.home-page-text02 {
    font-size: 12px;
    align-self: flex-start;
    margin-bottom: 5px;
}

.home-page-text05 {
    color: #C5B358;
    font-size: 12px;
    align-self: flex-start;
    text-decoration: underline;
}

.home-page-one-stop-airport-services {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 15px;
    flex-direction: column;
    justify-content: flex-start;
}

.home-page-text06 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 15px;
}

.home-page-container3 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.home-page-image1 {
    height: 160px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 10px;
}

.home-page-image2 {
    height: 160px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 10px;
}

.home-page-image3 {
    height: 160px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 10px;
}

.home-page-text07 {
    top: 230px;
    color: rgb(255, 255, 255);
    right: 0px;
    position: absolute;
    font-size: 20px;
    font-style: normal;
    text-align: left;
    font-family: Arial;
    font-weight: 600;
    margin-left: 5px;
    letter-spacing: 2px;
}

.home-page-recommended {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
    flex-direction: column;
    padding-bottom: 10px;
    justify-content: flex-start;
}

.home-page-text08 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 15px;
}

.home-page-container4 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.home-page-image4 {
    width: 80%;
    height: 194px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 10px;
}

.home-page-image5 {
    width: 80%;
    height: 194px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
}

.home-page-explore-more {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: 5px;
    align-items: flex-start;
    padding-top: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
    padding-right: 10px;
    flex-direction: column;
    justify-content: flex-start;
}

.home-page-text09 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 15px;
}

.home-page-container5 {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.home-page-text10 {
    color: var(--dl-color-gray-white);
    width: 200px;
    height: 30px;
    font-size: 10px;
    font-style: normal;
    text-align: center;
    font-weight: 700;
    margin-left: 5px;
    padding-top: 10px;
    padding-left: 2px;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: #c5b358;
}

.home-page-text11 {
    color: var(--dl-color-gray-white);
    width: 300px;
    height: 30px;
    font-size: 10px;
    text-align: center;
    margin-left: 15px;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    text-transform: capitalize;
    background-color: rgb(235, 216, 141);
}

.home-page-text12 {
    color: var(--dl-color-gray-white);
    width: 300px;
    height: 30px;
    font-size: 10px;
    align-self: center;
    text-align: center;
    margin-left: 15px;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: #ebd88d;
}

.home-page-text13 {
    color: var(--dl-color-gray-white);
    width: 200px;
    height: 30px;
    font-size: 10px;
    text-align: center;
    margin-left: 15px;
    padding-top: 10px;
    border-radius: var(--dl-radius-radius-radius8);
    background-color: #ebd88d;
}

.home-page-container6 {
    gap: 10px;
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    padding-top: 5px;
    margin-bottom: 2px;
    padding-bottom: 5px;
    justify-content: center;
}

.home-page-image6 {
    width: 180px;
    height: 250px;
    align-self: center;
    object-fit: cover;
    margin-right: 15px;
    padding-bottom: 12px;
}

.home-page-image7 {
    width: 180px;
    height: 240px;
    object-fit: cover;
}
