.ehailing01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.ehailing01-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ehailing01-navlink {
  top: 44px;
  left: 5px;
  width: var(--dl-size-size-medium);
  height: var(--dl-size-size-medium);
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
.ehailing01-navlink1 {
  top: 173px;
  left: -1px;
  width: 100%;
  height: var(--dl-size-size-xlarge);
  opacity: 0;
  position: absolute;
  text-decoration: none;
}
