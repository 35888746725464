.airport-transfer03-container {
    width: 100%;
    display: flex;
    z-index: 100;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.airport-transfer03-container1 {
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    opacity: 1;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('/activity/airport-transfer-02.svg');
}

.airport-transfer03-container2 {
    flex: 0 0 auto;
    left: 0px;
    width: 100%;
    border: 2px dashed rgba(120, 120, 120, 0.4);
    bottom: 0px;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
}

.airport-transfer03-image {
    width: 100%;
    opacity: 1;
    z-index: 100;
    object-fit: cover;
}

.airport-transfer03-navlink {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 300px;
    opacity: 0;
    z-index: 100;
    position: absolute;
    text-decoration: none;
}

.airport-transfer03-navlink1 {
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    height: 160px;
    opacity: 0;
    z-index: 100;
    position: absolute;
    text-decoration: none;
}
