.bottom-nav-bar-container {
  width: 100%;
  height: auto;
  display: flex;
  position: fixed !important;
  top: auto !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  margin-bottom: 0 !important;
  background: white;
  border-color: var(--dl-color-gray-black);
  border-style: hidden;
  border-width: 1px;
  padding-bottom: 5px;
  justify-content: space-between;
}
.bottom-nav-bar-navlink {
  display: contents;
}
.bottom-nav-bar-homepage {
  gap: 5px;
  flex: 0 0 auto;
  width: 78px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
}
.bottom-nav-bar-image {
  width: 25px;
  height: 26px;
  object-fit: cover;
}
.bottom-nav-bar-text {
  color: rgb(197, 179, 88);
  font-size: 10px;
}
.bottom-nav-bar-navlink1 {
  display: contents;
}
.bottom-nav-bar-trip {
  gap: 5px;
  flex: 0 0 auto;
  width: 78px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
}
.bottom-nav-bar-image1 {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.bottom-nav-bar-text1 {
  color: rgb(197, 179, 88);
  font-size: 10px;
}
.bottom-nav-bar-explore {
  gap: 5px;
  flex: 0 0 auto;
  width: 78px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.bottom-nav-bar-container1 {
  flex: 0 0 auto;
  width: auto;
  height: 25px;
  display: flex;
  justify-content: center;
}
.bottom-nav-bar-navlink2 {
  display: contents;
}
.bottom-nav-bar-container2 {
  flex: 0 0 auto;
  width: 35px;
  height: 35px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: rgb(197, 179, 88);
}
.bottom-nav-bar-image2 {
  width: 25px;
  height: 25px;
  object-fit: cover;
}
.bottom-nav-bar-text2 {
  color: rgb(197, 179, 88);
  font-size: 10px;
  margin-top: 15px;
}
.bottom-nav-bar-navlink3 {
  display: contents;
}
.bottom-nav-bar-activity {
  gap: 5px;
  flex: 0 0 auto;
  width: 78px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
}
.bottom-nav-bar-activity1 {
  width: 26px;
  height: 25px;
  object-fit: cover;
  text-decoration: none;
}
.bottom-nav-bar-text3 {
  color: rgb(197, 179, 88);
  font-size: 10px;
}
.bottom-nav-bar-more {
  gap: 5px;
  flex: 0 0 auto;
  width: 78px;
  height: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.bottom-nav-bar-navlink4 {
  display: contents;
}
.bottom-nav-bar-account {
  width: 26px;
  height: 25px;
  object-fit: cover;
  text-decoration: none;
}
.bottom-nav-bar-text4 {
  color: rgb(197, 179, 88);
  font-size: 10px;
}
.bottom-nav-bar-root-class-name {
  flex: 0;
  left: 0px;
  width: 100%;
  bottom: 0px;
  position: absolute;
  align-self: center;
  margin-bottom: 12px;
}
.bottom-nav-bar-root-class-name1 {
  width: 100%;
}
.bottom-nav-bar-root-class-name2 {
  left: 0px;
  width: 100%;
  bottom: 8px;
}
.bottom-nav-bar-root-class-name3 {
  top: 771px;
  right: -28px;
  position: static;
}
.bottom-nav-bar-root-class-name4 {
  left: 0px;
  width: 100%;
  bottom: 8px;
}
.bottom-nav-bar-root-class-name5 {
  left: 0px;
  bottom: 0px;
  position: static;
}
.bottom-nav-bar-root-class-name6 {
  left: 0px;
  bottom: 8px;
  height: auto;
  position: static;
  align-self: center;
  margin-top: 0px;
}
