.my-trip01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
}
.my-trip01-text {
  align-self: flex-start;
  margin-top: 50px;
  margin-left: 15px;
}
.my-trip01-image {
  width: 282px;
  height: 211px;
  margin-top: 80px;
  object-fit: cover;
}
.my-trip01-text1 {
  margin-top: 20px;
}
.my-trip01-text2 {
  width: 80%;
  margin-top: 10px;
  text-align: center;
}
.my-trip01-navlink {
  color: #ffffff;
  margin-top: 20px;
  padding-top: 10px;
  border-width: 0px;
  padding-left: 30px;
  border-radius: 17px;
  padding-bottom: 10px;
  text-decoration: none;
  background-color: #c5b358;
}
