.bus01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.bus01-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #f6f5f2;
}
.bus01-container02 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
}
.bus01-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.bus01-navlink {
  display: contents;
}
.bus01-image {
  z-index: 5;
  margin-top: auto;
  object-fit: cover;
  margin-left: 10px;
  margin-bottom: auto;
  text-decoration: none;
}
.bus01-text {
  width: 100%;
  margin: auto;
  text-align: center;
  font-weight: bold;
  margin-left: -40px;
}
.bus01-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}
.bus01-container05 {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.bus01-image1 {
  width: 80%;
  height: auto;
  z-index: 1;
  border-top: 1px solid lightgray;
  object-fit: fill;
}
.bus01-container06 {
  width: 20%;
  height: 100%;
  display: flex;
  border-top: 1px solid lightgray;
  align-items: center;
  border-left: 1px solid lightgray;
}
.bus01-image2 {
  width: 25%;
  margin: 25% auto;
  z-index: 4;
  object-fit: fill;
}
.bus01-container07 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.bus01-container08 {
  width: 90%;
  margin: 35px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.bus01-navlink1 {
  display: contents;
}
.bus01-image3 {
  width: 100%;
  object-fit: cover;
  text-decoration: none;
}
.bus01-container09 {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
}
.bus01-navlink2 {
  display: contents;
}
.bus01-image4 {
  align-self: center;
  margin-top: auto;
  object-fit: cover;
  margin-bottom: 5%;
  text-decoration: none;
}
.bus01-navlink3 {
  width: 100px;
  height: 40px;
  opacity: 0;
  z-index: 10;
  margin-top: -65px;
  margin-left: 150px;
  text-decoration: none;
}
.bus01-container10 {
  flex: 0 0 auto;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
