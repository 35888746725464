.hotel-search1-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.hotel-search1-container1 {
    flex: 0 0 auto;
    width: 100%;
    height: 1000px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.hotel-search1-image {
    width: 100%;
    object-fit: cover;
}

.hotel-search1-navlink {
    top: 0px;
    left: 0px;
    color: rgb(154, 134, 134);
    width: var(--dl-size-size-medium);
    height: var(--dl-size-size-large);
    z-index: 200;
    position: absolute;
    text-decoration: none;
}

.hotel-search1-navlink1 {
    top: 0px;
    color: rgb(154, 134, 134);
    right: -3px;
    width: 100%;
    bottom: 0px;
    height: 500px;
    margin: auto;
    z-index: 200;
    position: absolute;
    text-decoration: none;

}
