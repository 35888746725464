.airport-transfer01-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.airport-transfer01-navlink {
  display: contents;
}
.airport-transfer01-image {
  top: 55px;
  left: 16px;
  width: 30px;
  height: 30px;
  position: absolute;
  object-fit: cover;
  text-decoration: none;
}
.airport-transfer01-image1 {
  width: 100%;
  height: 248px;
  object-fit: cover;
}
.airport-transfer01-text {
  top: 109px;
  left: 16px;
  color: rgb(255, 255, 255);
  position: absolute;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
.airport-transfer01-text04 {
  top: 157px;
  left: 16px;
  color: #ffffff;
  position: absolute;
  font-size: 14px;
  line-height: 18px;
}
.airport-transfer01-container1 {
  flex: 0 0 auto;
  width: 90%;
  height: 520px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  align-self: center;
  box-shadow: 0px 1px 3px 0px #d4d4d4;
  margin-top: -24px;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #FFFFFF;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.airport-transfer01-container2 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: flex-start;
}
.airport-transfer01-button {
  width: auto;
  border-width: 0px;
  border-radius: 90px;
  background-color: #C5B358;
}
.airport-transfer01-text08 {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.airport-transfer01-button1 {
  color: #975634;
  font-size: 14px;
  line-height: 18px;
  border-width: 0px;
  border-radius: 90px;
  background-color: rgb(246, 240, 232);
}
.airport-transfer01-text11 {
  color: #C5B358;
}
.airport-transfer01-textinput {
  width: 100%;
  height: 50px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-700);
  border-radius: 90px;
}
.airport-transfer01-textinput1 {
  width: 100%;
  height: 50px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-700);
  border-radius: 90px;
}
.airport-transfer01-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
}
.airport-transfer01-textinput2 {
  width: 100%;
  height: 50px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-700);
  border-radius: 90px;
}
.airport-transfer01-image2 {
  top: 36px;
  right: 20px;
  width: 24px;
  height: 24px;
  position: absolute;
  align-self: center;
  object-fit: cover;
}
.airport-transfer01-textinput3 {
  width: 100%;
  height: 50px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-700);
  border-radius: 90px;
}
.airport-transfer01-textinput4 {
  width: 100%;
  height: 50px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-700);
  border-radius: 90px;
}
.airport-transfer01-navlink1 {
  color: #ffffff;
  width: 100%;
  height: 50px;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: center;
  padding-top: 0.5rem;
  border-width: 0px;
  border-radius: 90px;
  text-decoration: none;
  background-color: #C5B358;
}
.airport-transfer01-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.airport-transfer01-text17 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.airport-transfer01-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.airport-transfer01-text18 {
  color: #C5B358;
  width: 35px;
  height: 35px;
  font-size: 14px;
  align-self: flex-start;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
  background-color: rgb(246, 240, 232);
}
.airport-transfer01-text22 {
  color: #333333;
  font-size: 14px;
  align-self: center;
  line-height: 18px;
  margin-left: var(--dl-space-space-unit);
}
.airport-transfer01-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.airport-transfer01-text23 {
  color: #C5B358;
  width: 35px;
  height: 35px;
  font-size: 14px;
  align-self: flex-start;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
  background-color: rgb(246, 240, 232);
}
.airport-transfer01-text26 {
  color: rgb(51, 51, 51);
  font-size: 14px;
  align-self: center;
  line-height: 18px;
  margin-left: var(--dl-space-space-unit);
}
.airport-transfer01-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.airport-transfer01-text29 {
  color: #C5B358;
  width: 35px;
  height: 35px;
  font-size: 14px;
  align-self: flex-start;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
  background-color: rgb(246, 240, 232);
}
.airport-transfer01-text32 {
  color: rgb(51, 51, 51);
  font-size: 14px;
  align-self: center;
  line-height: 18px;
  margin-left: var(--dl-space-space-unit);
}
.airport-transfer01-container8 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
}
.airport-transfer01-text35 {
  color: #C5B358;
  width: 35px;
  height: 35px;
  font-size: 14px;
  align-self: flex-start;
  text-align: center;
  line-height: 36px;
  border-radius: 8px;
  background-color: rgb(246, 240, 232);
}
.airport-transfer01-text38 {
  color: rgb(51, 51, 51);
  font-size: 14px;
  align-self: center;
  line-height: 18px;
  margin-left: var(--dl-space-space-unit);
}
