.meet-greet07-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.meet-greet07-image {
  width: 100%;
  object-fit: cover;
}
.meet-greet07-container1 {
  flex: -0.5;
  width: 100%;
  border: 2px solid rgba(120, 120, 120, 0.4);
  bottom: 0;
  height: 150px;
  display: flex;
  position: sticky;
  align-self: end;
  margin-top: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.meet-greet07-navlink {
  display: contents;
}
.meet-greet07-image1 {
  width: 70%;
  height: 50px;
  object-fit: cover;
  border-color: #c5b358;
  border-width: 1px;
  border-radius: 30px;
  text-decoration: none;
}
.meet-greet07-navlink1 {
  display: contents;
}
.meet-greet07-image2 {
  width: 70%;
  height: 50px;
  margin-top: var(--dl-space-space-unit);
  object-fit: cover;
  border-radius: 30px;
  text-decoration: none;
}
