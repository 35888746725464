.my-trip06-container {
  width: 100%;
  display: flex;
  overflow: auto;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  min-height: 100vh;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
}
.my-trip06-text {
  align-self: flex-start;
  margin-top: 50px;
  margin-left: 15px;
}
.my-trip06-container1 {
  flex: 0 0 auto;
  width: 90%;
  height: 125px;
  display: flex;
  margin-top: 50px;
  align-items: flex-start;
  padding-top: 20px;
  border-color: var(--dl-color-gray-700);
  border-style: solid;
  border-width: 1px;
  padding-left: 25px;
  border-radius: 15px;
  padding-right: 25px;
  flex-direction: column;
}
.my-trip06-text1 {
  font-weight: 700;
}
.my-trip06-text2 {
  margin-top: 20px;
}
.my-trip06-button {
  color: rgb(255, 255, 255);
  right: 25px;
  width: 71px;
  bottom: 150px;
  height: 66px;
  position: absolute;
  font-size: 38px;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  background-color: #C5B358;
}
